import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";

import '../../Campaigns/campaigns.scss';
import Title from "../../../uiKit/Title/Title";
import ProgressBar from "../../../uiKit/ProgressBar/ProgressBar";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { blue010, blue100, gray010 } from "../../../uiKit/assets/colors/colors";
import { ArrowBack } from "../../../uiKit/assets/svg/icons";
import RecentDonationsTableAnonymous from "../../UnauthorizedPages/recentDonations";

import { getCampaign, setNotFound } from "../../../redux/state/campaignSlice";
import { setCommunityId, setImageURL } from "../../../redux/state/communitySlice";
import Badge from "../../Campaigns/Campaign/ActiveCampaign/campaignBadge";

const LandingCampaign = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const communityId = query.get("communityId");
    const campaignId = query.get("campaignId");

    const imageURL = useSelector(state => state.community.communityImageURL, shallowEqual);
    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);
    const notFound = useSelector(state => state.campaign.notFound, shallowEqual);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!communityId) {
            window.localStorage.setItem('communityId', communityId);
            dispatch(setCommunityId({ communityId }))
        }
    }, [communityId, dispatch])

    useEffect(() => {
        if (notFound) {
            history.push(`/anonymous/campaign-completed/${communityId}/${campaignId}`)
        }
    }, [notFound, history, campaignId, communityId])

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(getCampaign({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    if (screenWidth < 900) {
                        dispatch(setImageURL(response.campaign.images.mobile));
                    }
                    else {
                        dispatch(setImageURL(response.campaign.images.desktop));
                    }
                })
                .catch(({ status }) => {
                    if (status === 404) dispatch(setNotFound(true))
                });
        }
    }, [communityId, campaignId, screenWidth, dispatch])

    return (
        <>
            <div className="landingCampaignsWrapper" style={{ paddingTop: '40px' }}>

                <div
                    onClick={() => history.push({
                        pathname: '/',
                        search: '?pageType="campaigns'
                    })}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        gap: '16px',
                    }}
                >
                    <ArrowBack />
                    <Title
                        fontSize={16}
                        text={'ALL CAMPAIGNS'}
                    />
                </div>
                <div className='campaignInformationWrapper'>
                    <div style={{ position: 'relative' }}>
                        <img
                            className='photoWrapperCampaign'
                            alt=''
                            src={imageURL}
                            style={{ height: '316px' }}
                        />
                        <Badge campaignUrgencyType={campaign.campaignUrgencyType} />
                    </div>
                    <Title
                        text={campaign.name}
                        fontSize={screenWidth < 1200 ? 24 : 32}
                        lineHeight={screenWidth < 1200 ? 32 : 40}
                        className='ourBalanceTitle'
                    />
                    <div className='campaignProgressBar' style={{ marginBottom: 0 }}>
                        <div className='progressBar'>
                            <ProgressBar
                                campaignId={campaignId}
                                communityId={communityId}
                                target={campaign.amount}
                                color={blue100}
                                style={{
                                    boxShadow: 'none',
                                    padding: 0,
                                    backgroundColor: screenWidth > 900 ? gray010 : blue010
                                }}
                            />
                        </div>
                    </div>
                    {
                        !!campaign.description ? (
                            <Title
                                text={campaign.description}
                                style={{ marginBottom: '24px', marginTop: '20px' }}
                                fontWeight={400}
                            />
                        ) : (<div style={{ height: '8px' }} />)
                    }

                    <div className='transferButtons'>
                        <CustomButton
                            title='Donate'
                            onClick={() => history.push(`/campaign/anonymous/donate/${communityId}/${campaignId}`)}
                        />
                    </div>
                </div>
                <div className='campaignsWrapper'>
                    <Title
                        text='Recent Donations'
                        fontSize={24}
                        lineHeight={32}
                        className="recentHeader"
                    />
                    <RecentDonationsTableAnonymous />
                </div>
            </div>
        </>
    )
}

export default LandingCampaign;