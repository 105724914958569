import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import CustomInput from '../../../../uiKit/Input/CustomInput';
import CustomSelect from '../../../../uiKit/CustomSelect/CustomSelect';
import SearchInput from '../../../../uiKit/Input/SearchInput';
import Title from "../../../../uiKit/Title/Title";
import { DollarSign } from '../../../../uiKit/assets/svg/icons';
import { blue300 } from "../../../../uiKit/assets/colors/colors";
import ExcludeMembers from "../../../../uiKit/Input/ExcludeMembers";

import { onlyNumbers, validateCampaignName } from '../../../../helpers/validation';

import { getVerifiedMembers } from '../../../../redux/state/memberSlice';
import { getCampaignTypes, getCampaignUrgencyTypes } from "../../../../redux/state/campaignSlice";

const CampaignsInfos = ({
    campaign,
    setCampaign,
    excluded = [],
    suggestedUser = false
}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { campaignId } = useParams();

    const isLoading = useSelector(state => state.member.isLoading.getVerifiedMembers, shallowEqual);
    const isLoadingTypes = useSelector(state => state.campaign.isLoading.getCampaignTypes, shallowEqual);
    const isLoadingUrgencyTypes = useSelector(state => state.campaign.isLoading.getCampaignUrgencyTypes, shallowEqual);

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const recipients = useSelector(state => state.member.verifiedMembers, shallowEqual);
    const types = useSelector(state => state.campaign.types, shallowEqual);
    const urgencyTypes = useSelector(state => state.campaign.urgencyTypes, shallowEqual);
    const error = useSelector(state => state.campaign.error, shallowEqual);

    const [str, setStr] = useState('');
    const [recipient, setRecipient] = useState(null);

    const [titleError, setTitleError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [recipientError, setRecipientError] = useState('');
    const [amountError, setAmountError] = useState('');

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!communityId) {
            dispatch(getVerifiedMembers({ communityId, searchString: str }));
        }
    }, [str, dispatch, communityId])

    useEffect(() => {
        if (!types) {
            dispatch(getCampaignTypes())
        }
    }, [types, dispatch])

    useEffect(() => {
        if (!urgencyTypes) {
            dispatch(getCampaignUrgencyTypes())
        }
    }, [urgencyTypes, dispatch])

    useEffect(() => {
        if (!!campaign.recipient) {
            const isCurrentUser = recipients?.some(item => item.userId === campaign.recipient.id);
            if (isCurrentUser) {
                setRecipient(campaign.recipient)
            }
        }
    }, [recipients, campaign.recipient])

    useEffect(() => {
        if (!!error) {
            if (!campaign.name) {
                setTitleError('Required');
            }
            if (!campaign.campaignTypeId) {
                setTypeError('Required');
            }
            if (!campaign.recipientId) {
                setRecipientError('Required')
            }
            if (!campaign.amount) {
                setAmountError('Required')
            }
        }
    }, [campaign, error])

    const validateTitle = () => {
        if (!campaign.name) {
            setTitleError('Required')
        }
    }

    const validateType = () => {
        if (!campaign.campaignTypeId) {
            setTypeError('Required')
        }
    }

    const validateRecipient = () => {
        if (!campaign.recipientId) {
            setRecipientError('Required')
        }
    }

    const validateAmount = () => {
        if (!campaign.amount) {
            setAmountError('Required')
        }
    }

    const onChoose = (member) => {
        if (!!member) {
            setRecipient(member);
            const { userId } = member;
            setCampaign(prev => ({ ...prev, recipientId: userId }))
        }
    }

    const titleChangeHandler = (e) => {
        setCampaign(prev => ({ ...prev, name: e.target.value }));
        if (validateCampaignName(e.target.value)) {
            setTitleError('');
        } else {
            if (!e.target.value.length) {
                setTitleError('Required');
            } else {
                setTitleError('Invalid campaign name');
            }
        }
    }

    const amountChangeHandler = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            setCampaign(prev => ({ ...prev, amount: !!parseInt(value) ? parseInt(value) : 0 }));
            setAmountError('');
        } else {
            if (!value) {
                setAmountError('Required')
            } else {
                setAmountError('Invalid amount')
            }
        }
    }

    return (
        <div>
            <div className='inputGroup'>
                <CustomInput
                    label='Campaign title'
                    description="Do not include the recipient's name in the campaign title, please"
                    onChange={titleChangeHandler}
                    error={!!titleError}
                    errorMessage={titleError}
                    outFocus={validateTitle}
                    onFocus={() => setTitleError('')}
                    value={campaign.name}
                />
                <CustomSelect
                    label='Type of need'
                    data={types}
                    onChange={(e) => setCampaign(prev => ({ ...prev, campaignTypeId: e.target.value }))}
                    errorMessage={typeError}
                    menuWidth={screenWidth > 900 ? 320 : 368}
                    value={campaign.campaignTypeId}
                    outFocus={validateType}
                    onFocus={() => setTypeError('')}
                    menuPosition={10}
                    loading={isLoadingTypes}
                />
            </div>
            <div className='inputGroup middleInputs'>
                <CustomSelect
                    label='Campaign Type'
                    data={urgencyTypes}
                    onChange={(e) => setCampaign(prev => ({ ...prev, campaignUrgencyType: e.target.value }))}
                    menuWidth={screenWidth > 900 ? 320 : 368}
                    value={campaign.campaignUrgencyType}
                    menuPosition={10}
                    loading={isLoadingUrgencyTypes}
                />
                <CustomInput
                    label='Amount'
                    EndAdornment={DollarSign}
                    onChange={amountChangeHandler}
                    value={campaign.amount}
                    error={!!amountError}
                    errorMessage={amountError}
                    outFocus={validateAmount}
                    onFocus={() => setAmountError('')}
                    maxLength={12}
                />
            </div>
            <div className='inputGroup footerInputs'>
                <div className="customInput" >
                    <SearchInput
                        str={str}
                        setStr={setStr}
                        value={recipient}
                        label='Choose recipient'
                        data={!!recipients ? recipients : []}
                        onChoose={onChoose}
                        error={!!recipientError}
                        errorMessage={recipientError}
                        outFocus={validateRecipient}
                        description='This selection automatically exclude recipient from receiving message blast.'
                        onFocus={() => setRecipientError('')}
                        loading={isLoading}
                    />
                </div>
                <div className="customInput" >
                    <ExcludeMembers
                        label='Exclude members (optional)'
                        description='This selection excludes additional members from receiving message blast about the campaign.'
                        setCampaign={setCampaign}
                        excluded={excluded}
                        excludedIds={campaign.excludedMembers}
                    />
                </div>
            </div>
            {
                suggestedUser && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="suggestedUserInfo">
                            <Title
                                text="The campaign was suggested for recipient:"
                                fontWeight={400}
                            />
                            <Title
                                text={campaign.suggestedRecipient?.fullName}
                            />
                            <Title
                                text={campaign.suggestedRecipient?.email}
                            />
                            <Title
                                text={campaign.suggestedRecipient?.phoneNumber}
                            />
                        </div>
                        {
                            !!recipients && !recipients.some(recipient => recipient.userId === campaign.suggestedRecipient?.id) ? (
                                <Title
                                    text="Add member"
                                    fontWeight={400}
                                    color={blue300}
                                    style={{ marginTop: '16px', cursor: 'pointer', marginBottom: '24px' }}
                                    onClick={() => history.push(`/settings/admin/drafts/suggestion/${communityId}/${campaignId}/add-member`)}
                                />
                            ) : (<div style={{ height: '24px' }}></div>)
                        }
                    </div>
                )
            }
            <CustomInput
                multiline
                label='Description (optional)'
                description="Do not include the recipient's name in the campaign description, please"
                onChange={(e) => setCampaign(prev => ({ ...prev, description: e.target.value }))}
                height={56}
                maxLength={2000}
                value={campaign.description}
            />
        </div>
    )
}

export default CampaignsInfos;