import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, makeStyles } from '@material-ui/core';

import { blue050, gray010, gray300, gray500, gray800, red900 } from '../assets/colors/colors';
import { Arrow } from '../assets/svg/icons';
import Title from '../Title/Title';
import Verify from '../Verify/verify';
import CustomInputInfo from './CustomInputInfo';
import Loading from '../Loading/loading';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        border: "1px solid",
        borderRadius: "10px !important",
        textTransform: "none",
        background: gray010,
        color: gray800,
        borderColor: gray300,
        height: "40px",
        padding: "0 20px !important",
        fontFamily: 'Poppins',
        borderBottom: `1px solid ${gray500}`,
        fontSize: '14px',
        marginBottom: '28px !important'
    },
    error: {
        borderBottom: `1px solid ${red900}`,
        borderRadius: '4px'
    },
    disabled: {
        border: "1px solid",
        borderRadius: "10px",
        textTransform: "none",
        background: blue050,
        color: gray010,
        borderColor: blue050,
    },
    input: {
        padding: '0 !important',
        fontFamily: 'Poppins !important',
        color: `${gray800} !important`,
        lineHeight: '20px !important',
        fontSize: '14px !important'

    },
    notchedOutline: {
        borderWidth: '0 !important'
    }
}));

const useAutoStyles = makeStyles(theme => ({
    paper: {
        overflowY: 'scroll !important',
        maxHeight: '300px',
        boxShadow: '0px 4px 10px rgba(224, 238, 242, 0.8) !important'
    },
    listbox: {
        maxHeight: 'fit-content !important'
    },
    endAdornment: {
        position: 'static !important',
        top: '0 !important'
    }
}));

const RecipientOptionLabel = (props, option) => (
    <div {...props}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            cursor: 'pointer',
            width: '100%'
        }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '4px'
            }} >
                <Title
                    text={option.fullName}
                />
                <Verify status={option.isVerified} />
            </div>
            <Title
                text={option.email}
                fontWeight={400}
                fontSize={12}
                lineHeight={14}
            />
        </div>
    </div>
)

const CommunityOptionLabel = (props, community) => (
    <div {...props}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            width: '100%',
        }}
        >
            <Title
                text={community.name}
                color={gray800}
            />
            <Title
                text={`Active Campaigns: ${community.numberOfActiveCampaigns}`}
                fontSize={12}
                fontWeight={400}
            />
            {
                !!community.location && <Title
                    text={`Location: ${community.location}`}
                    fontSize={12}
                    fontWeight={400}
                />
            }
        </div>
    </div>
)

const SearchLabelOptions = [
    RecipientOptionLabel,
    CommunityOptionLabel,
]

const SearchInput = ({
    label,
    str,
    setStr,
    data,
    onChoose,
    error,
    errorMessage,
    outFocus,
    description,
    onFocus,
    value,
    loading,
    searchLabelOption = 0, //0,1
    hasNextPage = false,
    setPageSize,
    totalNumberOfItems,
    numberOfItems,
    placeholder,
}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const paperClasses = useAutoStyles();

    return (
        <div style={{ position: 'relative' }}>
            {
                !!label && <div className="label">{label}</div>
            }

            <Autocomplete
                id="autocomplete"
                open={open}
                disableClearable
                onOpen={() => {
                    setOpen(true);
                }}
                classes={paperClasses}
                onClose={() => {
                    setOpen(false);
                }}
                style={{
                    minHeight: '40px',
                    maxHeight: '90px',
                    minWidth: '220px',
                    background: 'transparant',
                    width: '100%',
                    padding: 0
                }}
                value={value}
                onChange={(_, value) => {
                    onChoose(value)
                }}
                onBlur={outFocus}
                onFocus={onFocus}
                options={data}
                getOptionLabel={(option) => option.fullName ?? option.name}
                renderOption={SearchLabelOptions[searchLabelOption]}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            error={!!errorMessage}
                            value={str}
                            onChange={(e) => setStr(e.target.value)}
                            placeholder={placeholder}
                            InputProps={{
                                ...params.InputProps,
                                classes,
                                endAdornment: (
                                    <>
                                        {
                                            loading && (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            )
                                        }
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    </>
                )}
                popupIcon={<Arrow />}
                PaperComponent={({ children }) => {
                    return (
                        <div style={{ position: 'relative' }}>
                            <InfiniteScroll
                                dataLength={numberOfItems}
                                next={() => setPageSize(prev => {
                                    if (totalNumberOfItems - prev < 20) {
                                        return prev + 20;
                                    } else {
                                        return prev + 10;
                                    }
                                })}
                                hasMore={hasNextPage}
                                scrollableTarget="scrollableDiv"
                                loader={<h4>Loading...</h4>}
                                style={{
                                    boxShadow: '0px 4px 10px rgba(224, 238, 242, 0.8)',
                                    backgroundColor: gray010,
                                }}
                                height={200}
                            >
                                {children}
                            </InfiniteScroll>
                            {
                                loading && <Loading />
                            }
                        </div>
                    )
                }}
            />
            <CustomInputInfo
                error={error}
                errorMessage={errorMessage}
                description={description}
            />
        </div>
    );
}

export default SearchInput;