import React, { useCallback, useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useLocation, useParams } from "react-router";

import '../campaign.scss';
import Title from "../../../../uiKit/Title/Title";
import CustomButton from "../../../../uiKit/Button/CustomButton";
import { BlueMail, SingleUser } from "../../../../uiKit/assets/svg/icons";
import { GreyPenSmall } from '../../../../uiKit/assets/svg/icons';
import {
    blue300,
    gray650
} from "../../../../uiKit/assets/colors/colors";
import Header from "../../../../components/Header/header";
import RecentDonationsTable from "../RecentDonations/recentDonationsTable";
import Badge from "./campaignBadge";
import CampaignBalanceInfo from "./campaignBalanceInfo";

import { getCampaign } from "../../../../redux/state/campaignSlice";
import { getMember } from "../../../../redux/state/memberSlice";
import AnimatedLoading from "../../../../uiKit/Loading/animatedLoading";

const ActiveCampaign = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { state, pathname } = useLocation();
    const { campaignId, communityId } = useParams();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);
    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);
    const isLoading = useSelector(state => state.campaign.isLoading.common, shallowEqual);
    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);
    const userId = useSelector(state => state.account.id, shallowEqual);

    const [previousRoute, setPreviousRoute] = useState('');

    useEffect(() => {
        if (!state) {
            setPreviousRoute('/campaigns');
            return;
        }

        setPreviousRoute(`${state.pathname}${state.search}`);

    }, [state])

    useEffect(() => {
        if (!communityId || !campaignId) return;
        dispatch(getCampaign({ communityId, campaignId }))
            .then(unwrapResult)
            .then(() => { })
            .catch(({ status }) => {
                if (status === 404) {
                    history.push(`/campaigns/campaign-completed/${communityId}/${campaignId}`)
                }
            })
    }, [communityId, campaignId, dispatch, history])

    const onRecipientClick = () => {
        if (!!campaign.recipient && !!campaign.recipient.id) {
            dispatch(getMember({ communityId, userId: campaign.recipient.id }))
                .then(unwrapResult)
                .then(() => history.push(`/members/member/${campaign.recipient.id}`))
                .catch(() => toast.info('User is not a member of this community'))
        }
    }

    const donateHandler = useCallback(() => {
        ReactGA.event('donate_campaign_start')
        history.push(`/campaigns/${campaignId}/donate/${communityId}`)
    }, [history, campaignId, communityId])

    const transferFromOurBalanceHandler = useCallback(() => {
        ReactGA.event('transfer_from_our_balance');
        history.push(`/campaigns/transfer/${campaign.name}/${campaignId}`);
    }, [history, campaign, campaignId])

    const inviteFriendHandler = useCallback(() => {
        ReactGA.event('invite_friend_to_donate');
        history.push(`/campaigns/campaign/${campaignId}/invite-friend`)
    }, [history, campaignId])

    const navigateToActiveCampaignEdit = () => {
        history.push(`${pathname}/edit`)
    }

    return (
        <>
            <Header title='All campaigns' onClick={() => history.push(previousRoute)} hasMenu={!!communities?.length} clickable={!!communities?.length} />
            <div className='campaignInfoHeaderWrapper'>

                <div style={{ position: 'relative' }}>
                    <img
                        className='photoWrapperCampaign'
                        alt=''
                        src={screenWidth < 900 ? campaign.images.mobile : campaign.images.desktop}
                    />
                    <Badge campaignUrgencyType={campaign.campaignUrgencyType} />
                </div>
                <div className="recipient">
                    <Title
                        text={campaign.name}
                        fontSize={screenWidth > 1200 ? 32 : 24}
                        lineHeight={screenWidth > 1200 ? 40 : 32}
                        className='ourBalanceTitle'
                    />
                    {!!isAdmin && (
                        <div className="menuItem" onClick={navigateToActiveCampaignEdit}>
                            <GreyPenSmall />
                        </div>
                    )}
                </div>
                <CampaignBalanceInfo />
                {
                    !!isAdmin &&
                    !!campaign &&
                    !!campaign.recipient && (
                        <div className='recipient' >
                            <div className='icon'><SingleUser /></div>
                            <div className='word'>
                                <Title text='Recipient:' fontSize={16} color={gray650} />
                            </div>
                            <Title
                                text={campaign.recipient.fullName}
                                fontSize={16}
                                color={blue300}
                                style={{ marginLeft: '16px', cursor: 'pointer' }}
                                onClick={onRecipientClick}
                            />
                        </div>
                    )
                }
                {
                    !!campaign.description ? (
                        <Title
                            text={campaign.description}
                            style={{ marginBottom: '24px', marginTop: '20px' }}
                            fontWeight={400}
                        />
                    ) : (<div style={{ height: '8px' }} />)
                }
                <div className='transferButtons'>
                    <CustomButton
                        title='Donate'
                        onClick={donateHandler}
                    />
                    {
                        !!isAdmin && (
                            <CustomButton
                                title='Transfer from Our Balance'
                                isWhite
                                classNames='margin16to0'
                                onClick={transferFromOurBalanceHandler}
                            />
                        )
                    }
                    {
                        !userId && (
                            <CustomButton
                                title='Invite friend to donate'
                                isWhite
                                Icon={BlueMail}
                                classNames='margin16to0'
                                onClick={inviteFriendHandler}
                            />
                        )
                    }
                </div>
                <AnimatedLoading loading={isLoading} full={false} />
            </div>
            <div className='campaignsWrapper'>
                <Title
                    text='Recent Donations'
                    fontSize={24}
                    lineHeight={32}
                    className="recentHeader"
                />
                <RecentDonationsTable
                    isAdmin={!!isAdmin}
                    communityId={communityId}
                />
            </div>
        </>
    )
}

export default ActiveCampaign;