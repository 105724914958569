import { Pagination } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { gray550, gray800 } from "../assets/colors/colors";
import Title from "../Title/Title";


const CustomPagination = ({
    data,
    tableUrl,
    style,
    pageSize = 8,
}) => {

    const {
        numberOfItems,
        page,
        totalNumberOfItems,
        hasPreviousPage,
        hasNextPage,
    } = data;

    const { search } = useLocation();
    const history = useHistory();

    const onChangeHandler = (event, value) => {
        const query = new URLSearchParams(search);
        query.set("page", value);
        history.push({
            pathname: tableUrl,
            search: query.toString()
        });
    }

    return (
        !!totalNumberOfItems && (
            <div className='customTablePaginationWrapper' style={style}>
                <div className='flexAlign' >
                    <Title
                        text='Showing'
                        fontWeight={500}
                        lineHeight={16}
                        color={gray550}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontFamily: 'Montserrat'
                        }}
                    />
                    <Title
                        text={`${(page - 1) * pageSize + 1}`}
                        fontWeight={600}
                        lineHeight={16}
                        color={gray800}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'
                        }}
                    />
                    <Title
                        text='to'
                        fontWeight={500}
                        color={gray550}
                        lineHeight={16}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'

                        }}
                    />
                    <Title
                        text={`${(page - 1) * pageSize + numberOfItems}`}
                        fontWeight={600}
                        lineHeight={16}
                        color={gray800}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'
                        }}
                    />
                    <Title
                        text='of'
                        fontWeight={500}
                        color={gray550}
                        lineHeight={16}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'
                        }}
                    />
                    <Title
                        text={`${totalNumberOfItems}`}
                        fontWeight={600}
                        lineHeight={16}
                        color={gray800}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'
                        }}
                    />
                    <Title
                        text='results'
                        fontWeight={500}
                        color={gray550}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: '7px',
                            fontFamily: 'Montserrat'
                        }}
                    />
                </div>
                <div className='flexAlign'  >
                    <Pagination
                        count={Math.ceil(totalNumberOfItems / pageSize)}
                        page={page}
                        onChange={onChangeHandler}
                        showFirstButton={hasPreviousPage}
                        showLastButton={hasNextPage}
                        className='pagination'
                        shape="rounded"

                    />
                </div>
            </div>
        )
    )
}

export default CustomPagination;