import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles, SwipeableDrawer } from "@material-ui/core";
import moment from 'moment';
import './landingPage.scss'

import Boy from '../../../uiKit/assets/png/welcome.png';
import Terms from '../LandingPage/Terms/terms';
import Policy from '../LandingPage/Terms/policy';
import ScrollToTopIcon from '../../../components/ScrollToTopIcon/scrollToTopIcon';
import { Logo } from '../../../uiKit/assets/svg/logo';
import Invite from '../../../uiKit/assets/png/invite.png';
import CustomButton from '../../../uiKit/Button/CustomButton';
import { Close, MenuIcon } from '../../../uiKit/assets/svg/icons';
import { blue010, blue300 } from '../../../uiKit/assets/colors/colors';
import Title from '../../../uiKit/Title/Title';
import Check from '../../../uiKit/assets/svg/blueCheck.svg';
import Up from '../../../uiKit/assets/svg/arrowUp.svg';
import Down from '../../../uiKit/assets/svg/arrowDown.svg';
import {
    Vector01,
    Vector01Mobile,
    Vector02,
    Vector02Mobile,
    Vector03,
    Vector03Mobile,
    Vector04,
    Vector04Mobile
} from '../../../uiKit/assets/svg/vectors';
import { Youtube } from '../../../uiKit/assets/svg/socialIcons';
import {
    STEPS_CONTENT_TEXT_PART_1,
    STEPS_CONTENT_TEXT_PART_2,
    YOUTUBE_LINK
} from '../../../helpers/constants';

import { parseToken } from '../../../redux/state/accountSlice';
import { getCommunityPublic } from '../../../redux/state/communitySlice';
import { setScreenWidth } from '../../../redux/state/commonSlice';

const useStyles = makeStyles(() => ({
    paper: {
        width: '100%',
        backgroundColor: blue010,
        maxHeight: '538px',
        height: '100%'
    }
}))

const MobileMenu = ({
    login,
    signup,
}) => {

    const history = useHistory();

    const drawerClasses = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <MenuIcon />
            </div>
            <SwipeableDrawer
                anchor='right'
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                classes={drawerClasses}
            >
                <div className='sidebarMobile'>
                    <div
                        className='mobileMenuLanding'
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <div
                                style={{ width: '172px', cursor: 'pointer' }}
                                onClick={() => history.goBack()}
                            >
                                <Logo />
                            </div>
                            <div onClick={() => setIsOpen(false)} >
                                <Close />
                            </div>
                        </div>
                        <div style={{
                            width: '100%',
                            margin: 'auto'
                        }}
                        >
                            <CustomButton
                                title='Log in'
                                isWhite
                                onClick={() => login()}
                                style={{ marginBottom: '16px' }}
                            />
                            <CustomButton
                                title='Sign up'
                                onClick={() => signup()}
                            />
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}

const LandingPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { search, pathname } = useLocation();
    const query = new URLSearchParams(search);

    const [showMore, setShowMore] = useState(false);
    const user = useSelector(state => state.account.userByParseToken, shallowEqual);
    const communityName = useSelector(state => state.community.communityInfoPublic.name, shallowEqual);
    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const privacyOpen = query.get("pageType") === "privacy" ? true : false;
    const termsOpen = query.get("pageType") === "terms" ? true : false;

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/landing-page_short' })
    }, [])

    useEffect(() => {
        const invitationToken = encodeURIComponent(new URLSearchParams(search).get('token'));
        if (!!invitationToken && !user.id) {
            dispatch(parseToken(invitationToken))
        }

    }, [user, search, dispatch])

    useEffect(() => {
        if (!!user.communityId) {
            dispatch(getCommunityPublic(user.communityId))
        }
    }, [user, dispatch])

    const onLogoClick = useCallback(() => {
        window.scrollTo(0, 0);
        history.goBack();
    }, [history])

    const signupHandler = () => {

        ReactGA.event('sign_up_short_landing');

        const type = new URLSearchParams(search).get('type');

        if (!type) return;
        if (type === 'new') history.push(`/sign-up${search}`)
        if (type === 'create') history.push(`/create-account${search}`)
    }

    const loginHandler = () => {

        ReactGA.event('log_in_short_landing');

        const invitationToken = encodeURIComponent(new URLSearchParams(search).get('token'));
        if (!invitationToken) return;

        if (!!invitationToken) history.push(`/login/${invitationToken}`);

    }

    const onPrivacyClick = useCallback(() => {
        ReactGA.event('privacy_policy_short_landing');
        const query = new URLSearchParams(search);
        query.set("pageType", "privacy");
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, history, pathname])

    const onTermsClick = useCallback(() => {
        ReactGA.event('terms_and_conditions_short_landing');
        const query = new URLSearchParams(search);
        query.set("pageType", "terms");
        history.replace({
            pathname,
            search: query.toString()
        })
        window.scrollTo(0, 0);
    }, [search, history, pathname])

    return (
        <div className='wrapperAlternate' >
            <div className='container' >
                <div
                    className='mainSection'
                    style={{
                        height: !termsOpen && !privacyOpen ? screenWidth > 600 ? '874px' : 'max-content' : 'max-content'
                    }}
                >
                    <div className='header'>
                        <div
                            style={{ width: '172px', cursor: 'pointer' }}
                            onClick={onLogoClick}
                        >
                            <Logo />
                        </div>
                        <CustomButton
                            isWhite
                            title='Log in'
                            onClick={loginHandler}
                        />
                        <div className='mobileMenu'>
                            <MobileMenu
                                login={loginHandler}
                                signup={signupHandler}
                            />
                        </div>
                    </div>
                    <div className='mainContent' style={{ display: !termsOpen && !privacyOpen ? 'flex' : 'none' }}>
                        <div className='content'>
                            <div className='mainTitle'>
                                We know. No one likes to <span>Sign up.</span> Another <span>platform</span>...but this is different.
                            </div>
                            <Title
                                text="By signing up, you will be able to DIRECTLY help those within your own community, quickly and discreetly whenever the need arises."
                                fontSize={screenWidth > 1200 ? 21.5 : 16}
                                fontWeight={screenWidth > 1200 ? 300 : 400}
                                lineHeight={screenWidth > 1200 ? 30 : 20}
                                style={{ marginBottom: '67px' }}
                            />
                            <CustomButton
                                title='Sign up'
                                onClick={signupHandler}
                            />
                        </div>
                        <img className='signUpImage' src={Boy} alt='boy' />
                        <div className='signUpButton'>
                            <CustomButton
                                title='Sign up'
                                onClick={signupHandler}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: termsOpen && !privacyOpen ? 'block' : 'none',
                            marginTop: '60px'
                        }}
                    >
                        <Terms />
                    </div>
                    <div
                        style={{
                            display: !termsOpen && privacyOpen ? 'block' : 'none',
                            marginTop: '60px'
                        }}
                    >
                        <Policy />
                    </div>
                </div>

                <div className='stepsSection'>
                    <div className='stepsIntro' style={{ display: !termsOpen && !privacyOpen ? 'flex' : 'none' }}>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '0 24px'
                        }}>
                            <Title
                                text='We are a true non for profit.'
                                fontWeight={600}
                                fontSize={38}
                                lineHeight={45}
                                className='stepsTitleMobile'
                            />
                            <img src={Invite} alt='invite' />
                        </div>
                        <div className='stepsContent'>
                            <Title
                                text='We are a true non for profit.'
                                fontWeight={600}
                                fontSize={60}
                                lineHeight={67}
                                className='stepsTitleDesktop'
                            />
                            <div className='stepsTexts'>
                                <Title
                                    text={STEPS_CONTENT_TEXT_PART_1}
                                    fontWeight={screenWidth > 1200 ? 300 : 400}
                                    fontSize={18}
                                    lineHeight={25.2}
                                    overflowText={false}
                                />
                                {
                                    (showMore || screenWidth > 1200) && (
                                        <Title
                                            text={STEPS_CONTENT_TEXT_PART_2}
                                            fontWeight={screenWidth > 1200 ? 300 : 400}
                                            fontSize={18}
                                            lineHeight={25.2}
                                            overflowText={false}
                                            style={{ marginTop: '20px' }}
                                        />
                                    )
                                }
                                <div
                                    style={{
                                        fontWeight: 300,
                                        fontSize: '18px',
                                        lineHeight: '25.2px',
                                        color: blue300,
                                        margin: '24px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {
                                        screenWidth < 1200 ? !showMore ? (
                                            <img src={Down} alt='' style={{ width: '18px', height: '25px', margin: 0 }} />
                                        ) : (
                                            <img src={Up} alt='' style={{ width: '18px', height: '25px', margin: 0 }} />
                                        ) : null
                                    }
                                    {
                                        screenWidth < 1200 ? !showMore ? 'Read more' : 'Less' : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="blueCircle" />
                        <div className="yellowCircle" />
                        <div className="blueLittleCircle" />
                    </div>
                    <div className="steps" style={{ display: !termsOpen && !privacyOpen ? 'block' : 'none' }} >
                        <div style={{ position: 'relative' }}>
                            <div
                                className="padding4Vector"
                                style={{
                                    width: '50%',
                                    marginLeft: '12%',
                                    paddingTop: '4.9%',
                                    paddingBottom: '11.4%'
                                }}>
                                <Title
                                    text="01"
                                    fontWeight={800}
                                    fontSize={screenWidth > 1200 ? 48 : 36}
                                    lineHeight={screenWidth > 1200 ? 72 : 54}
                                />
                                <Title
                                    text='Everyone in our community signs up'
                                    fontWeight={400}
                                />
                            </div>
                            <div className='vector01' >
                                <Vector01 />
                            </div>
                            <div className='vector01Mobile' >
                                <Vector01Mobile />
                            </div>
                            <div className='vector02' >
                                <Vector02 />
                            </div>
                        </div>
                        <div style={{ position: 'relative' }} >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',

                            }}>
                                <div
                                    className="padding4Vector"
                                    style={{
                                        width: screenWidth > 1200 ? '42%' : '55%',
                                        marginRight: '9%',
                                        paddingBottom: '8%',
                                        paddingTop: screenWidth > 1200 ? '3.4%' : '25%'
                                    }}>
                                    <Title
                                        text="02"
                                        fontWeight={800}
                                        fontSize={screenWidth > 1200 ? 48 : 36}
                                        lineHeight={screenWidth > 1200 ? 72 : 54}
                                    />
                                    <Title
                                        text="If a need arises, a text goes out with the goal and a link to contribute. The recipient is automatically excluded from all messaging."
                                        fontWeight={400}
                                    />
                                </div>
                            </div>
                            <div className='vector02Mobile' >
                                <Vector02Mobile />
                            </div>
                            <div className="vector03" >
                                <Vector03 />
                            </div>
                        </div>
                        <div style={{ position: 'relative' }} >
                            <div
                                className="padding4Vector"
                                style={{
                                    width: screenWidth > 1200 ? '34%' : '55%',
                                    marginLeft: '12.5%',
                                    paddingBottom: '6%',
                                    paddingTop: screenWidth > 1200 ? '3.1%' : '15%'
                                }}>
                                <Title
                                    text="03"
                                    fontWeight={800}
                                    fontSize={screenWidth > 1200 ? 48 : 36}
                                    lineHeight={screenWidth > 1200 ? 72 : 54}
                                />
                                <Title
                                    text="Everyone can pitch in and cover the campaign quickly and quietly."
                                    fontWeight={400}
                                />
                            </div>
                            <div className='vector03Mobile' >
                                <Vector03Mobile />
                            </div>
                            <div className="vector04" >
                                <Vector04 />
                            </div>
                        </div>
                        <div style={{ position: 'relative', zIndex: 100 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                <div
                                    className="padding4Vector"
                                    style={{
                                        width: screenWidth > 1200 ? '21%' : '45%',
                                        marginRight: '28%',
                                        paddingTop: screenWidth > 1200 ? '9.1%' : '40%'
                                    }}
                                >
                                    <div style={{
                                        width: '36px',
                                        height: '48px',
                                        marginBottom: '8px'
                                    }}>
                                        <img alt='check' src={Check} />
                                    </div>
                                    <Title
                                        text={`Please join our ${!!communityName ? communityName : ''} group today.`}
                                        fontWeight={400}
                                    />
                                </div>
                            </div>
                            <div className='vector04Mobile' >
                                <Vector04Mobile />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: screenWidth > 1200 ? 0 : '80px',
                        display: !termsOpen && !privacyOpen ? 'flex' : 'none'
                    }}>
                        <CustomButton
                            title='Join now'
                            onClick={signupHandler}
                        />
                    </div>
                    <div className='footerAlternate'>
                        <div
                            className='footerLogo'
                            onClick={onLogoClick}
                        >
                            <Logo />
                        </div>
                        <div className="dividerFooter" />

                        <div className="connections" >
                            <Title
                                text={`© ${moment().format('YYYY')} OurCommunity`}
                                fontWeight={400}
                                lineHeight={24}
                            />
                            <Title
                                text="Privacy Policy"
                                fontWeight={400}
                                lineHeight={24}
                                onClick={onPrivacyClick}
                                style={{ cursor: 'pointer' }}
                                className='hoverNav'
                            />
                            <Title
                                text="Terms and Conditions"
                                fontWeight={400}
                                lineHeight={24}
                                onClick={onTermsClick}
                                style={{ cursor: 'pointer' }}
                                className='hoverNav'
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '160px' }}>
                                <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                                    <Youtube />
                                </a>
                            </div>
                        </div>

                        <div className="connectionsMobile" >
                            <div >
                                <Title
                                    text="Privacy Policy"
                                    fontWeight={400}
                                    className='hoverNav'
                                    lineHeight={24}
                                    style={{ marginBottom: '19px', cursor: 'pointer' }}
                                    onClick={onPrivacyClick}
                                />
                                <Title
                                    text={`© ${moment(Date.now()).format('YYYY')} OurCommunity`}
                                    fontWeight={400}
                                    lineHeight={24}
                                />
                            </div>
                            <div>
                                <Title
                                    text="Terms and Conditions"
                                    fontWeight={400}
                                    className='hoverNav'
                                    lineHeight={24}
                                    style={{ marginBottom: '19px', cursor: 'pointer' }}
                                    onClick={onTermsClick}
                                />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                                        <Youtube />
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div className="dividerFooter" />

                        <Title
                            text="Dedicated in memory of Chaim Meir Halevi Lieberman o.b.m."
                            fontWeight={400}
                            lineHeight={24}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '24px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <ScrollToTopIcon />
        </div>
    )
}

export default LandingPage;