export const black = '#000000';

// grey
export const gray010 = '#FFFFFF';
export const gray050 = '#FAFAFA';
export const gray100 = '#F4F4F4';
export const gray300 = '#DFDDDD';
export const gray500 = '#BBBBBC';
export const gray550 = '#A3A3A3';
export const gray600 = '#8F8F92';
export const gray650 = '#7A7A7A';
export const gray800 = '#4A4A4A';
export const gray900 = '#3C3C3F';

// blue
export const blue010 = '#F1F8FF';
export const blue020 = '#D0E7FF';
export const blue050 = '#B7DBFF';
export const blue100 = '#7DBEFF';
export const blue300 = '#379AFF';
export const blue500 = '#2087EF';
export const blue600 = '#0671DF';
export const blue800 = '#045BB4';
export const blue900 = '#054B93';

// red
export const red100 = '#FFEAEA';
export const red500 = '#FFA6A6';
export const red600 = '#FF5C5C';
export const red800 = '#FF4444';
export const red900 = '#E32020';

// green
export const green010 = '#DFFFEF';
export const green050 = '#B7F0D4';
export const green100 = '#06C270';
export const green300 = '#05A660';

//yellow
export const yellow100 = '#FFFADF';
export const yellow300 = '#FFE146';
export const yellow400 = '#FABF1E';