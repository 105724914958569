import { useHistory } from "react-router-dom";

const CampaignsWrapper = () => {
    const history = useHistory();
    const token = window.localStorage.getItem('token');

    if (!!token) history.push(`/campaigns/campaign`)

    history.push({
        pathname: `/`,
        search: `?pageType=campaign`
    });

}

export default CampaignsWrapper;