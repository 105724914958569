import moment from "moment";
import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { blue300, gray800 } from "../../uiKit/assets/colors/colors";
import { Calendar, SingleUser, Target, Users } from "../../uiKit/assets/svg/icons";
import Title from "../../uiKit/Title/Title";


import { clearCommunity, setCommunityId } from "../../redux/state/communitySlice";
import { clearCampaigns, clearDrafts, clearSuggestions } from "../../redux/state/campaignSlice";
import { clearAdmins } from "../../redux/state/memberSlice";
import { toast } from "react-toastify";
import { clearNotifications } from "../../redux/state/notificationSlice";

const TransferInfoCard = ({ transfer, key }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const data = transfer.campaign ?? transfer.to.campaign ?? transfer.to.community;
    const isCampaign = Boolean(transfer.campaign ?? transfer.to.campaign);

    const currentCommunities = useSelector(state => state.community.memberCommunities, shallowEqual);

    const communityClickHandler = useCallback(async (communityId) => {
        dispatch(clearCommunity());
        dispatch(clearCampaigns());
        dispatch(clearAdmins());
        dispatch(clearDrafts());
        dispatch(clearSuggestions());
        dispatch(clearNotifications());
        dispatch(setCommunityId({ communityId }));
        window.localStorage.setItem('communityId', communityId);
        history.push('/our-balance');
    }, [dispatch, history])

    const redirectCampaign = useCallback((campaign, communityId) => {
        if (!campaign) {
            history.push('/our-balance');
            return;
        }

        if (campaign.status === 'launched') {
            history.push(`/campaigns/campaign/active/${communityId}/${campaign.id}`);
            return;
        }

        if (campaign.status === 'completed' || campaign.status === 'stopped') {
            history.push(`/campaigns/campaign/completed/${campaign.id}`);
            return;
        }
    }, [history])

    const campaignClickHandler = useCallback((campaign, communityId) => {
        if (!!communityId) {
            if (!!currentCommunities && !currentCommunities.some(community => community.id === communityId)) {
                toast.info('You are not member of this community anymore');
                return;
            }

            communityClickHandler(communityId)
                .then(() => redirectCampaign(campaign, communityId))
        }
    }, [currentCommunities, redirectCampaign, communityClickHandler])

    return (
        <div className='donationListOurBalanceRow' key={key}>
            <div style={{
                display: 'flex',
                cursor: 'pointer',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <div style={{ display: 'flex', marginBottom: '4px' }}>
                    <div className='transferInfoIcon'>
                        <Target />
                    </div>
                    <Title
                        text={isCampaign ? data?.name : 'Our balance'}
                        style={{ marginLeft: '12px' }}
                        fontWeight={400}
                        color={blue300}
                        onClick={
                            !!data?.communityId ?
                                () => campaignClickHandler(data, data.communityId) :
                                () => communityClickHandler(data.id)
                        }
                    />
                </div>
                {
                    !!transfer?.user ? (
                        <div style={{ display: 'flex', marginBottom: '4px' }}>
                            <div className='transferInfoIcon'>
                                <SingleUser />
                            </div>
                            <Title
                                text={transfer.user.fullName}
                                style={{ marginLeft: '12px' }}
                                fontWeight={400}
                                color={blue300}
                                onClick={() => history.push(`/members/member/${transfer.user.id}`)}
                            />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', marginBottom: '4px' }}>
                            <div className='transferInfoIcon'>
                                <Users />
                            </div>
                            <Title
                                text={isCampaign ? transfer?.to.community.name : data?.name}
                                style={{ marginLeft: '12px' }}
                                fontWeight={400}
                                color={blue300}
                                onClick={() => communityClickHandler(data?.communityId ?? data?.id)}
                            />
                        </div>
                    )
                }
                <div style={{ display: 'flex', marginBottom: '4px' }}>
                    <div className='transferInfoIcon'>
                        <Calendar />
                    </div>
                    <Title
                        text={moment(transfer.date).format('MM/DD/YYYY')}
                        style={{ marginLeft: '12px' }}
                        fontWeight={400}
                        color={gray800}
                    />
                    <Title
                        text={moment(transfer.date).format('h:mm a')}
                        style={{ marginLeft: '12px' }}
                        fontWeight={400}
                        color={gray800}
                    />
                </div>
            </div>
            <Title
                text={`$${transfer.amount.toLocaleString()}`}
                style={{ width: 'max-content', overflow: 'visible' }}
            />
        </div>
    )
}

export default TransferInfoCard;