import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './landingPage.scss';
import LandingHeader from './landingHeader';
import SignupSection from './signupSection';
import Groups from './groups';
import HowItWorks from './howItWorks';
import Advantages from './advantages';
import About from './about';
import AboutGradient from './aboutGradient';
import Team from './team';
import ContactUs from './contactUs';
import Footer from './footer';
import Terms from './Terms/terms';
import Policy from './Terms/policy';
import HowTo from './tutorials';
import FAQ from './faq';
import OurValues from './ourValues';
import LandingCampaigns from './landingCampaigns';
import LandingCampaign from './landingCampaign';
import Support from './support';

import ScrollToTopIcon from '../../../components/ScrollToTopIcon/scrollToTopIcon';

import { setScreenWidth } from '../../../redux/state/commonSlice';

const LandingPage = () => {

    const dispatch = useDispatch();
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [referances, setReferances] = useState({});

    const privacyOpen = query.get("pageType") === "privacy" ? true : false;
    const termsOpen = query.get("pageType") === "terms" ? true : false;
    const tutorialsOpen = query.get("pageType") === "tutorials" ? true : false;
    const FAQOpen = query.get("pageType") === "FAQ" ? true : false
    const ourValuesOpen = query.get("pageType") === "values" ? true : false;
    const campaigns = query.get("pageType") === "campaigns" ? true : false;
    const campaign = query.get("pageType") === "campaign" ? true : false;

    const updateReferance = useCallback((referance) => setReferances(referance), []);

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/landing-page' })
    }, [])

    return (
        <div className='wrapper' >
            <div className='container'>
                <LandingHeader referances={referances} />
                <div style={
                    !termsOpen &&
                        !privacyOpen &&
                        !tutorialsOpen &&
                        !ourValuesOpen &&
                        !FAQOpen &&
                        !campaigns &&
                        !campaign ? { display: 'block' } : { display: 'none' }}>
                    <SignupSection />
                    <Groups />
                    <HowItWorks setRef={updateReferance} />
                    <Advantages setRef={updateReferance} />
                    <Support />
                    <About />
                    <AboutGradient setRef={updateReferance} />
                    <Team />
                    <ContactUs setRef={updateReferance} />
                </div>
                <div style={termsOpen ? { display: 'block' } : { display: 'none' }}>
                    <Terms />
                </div>
                <div style={privacyOpen ? { display: 'block' } : { display: 'none' }}>
                    <Policy />
                </div>
                <div style={tutorialsOpen ? { display: 'block' } : { display: 'none' }}>
                    <HowTo />
                </div>
                <div style={ourValuesOpen ? { display: 'block' } : { display: 'none' }}>
                    <OurValues />
                </div>
                <div style={FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <FAQ />
                </div>
                <div style={campaigns ? { display: 'block' } : { display: 'none' }}>
                    <LandingCampaigns />
                </div>
                <div style={campaign ? { display: 'block' } : { display: 'none' }}>
                    <LandingCampaign />
                </div>
                <Footer referances={referances} />
            </div>
            <ScrollToTopIcon />
        </div>
    )
}

export default LandingPage;