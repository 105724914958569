import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dialog, makeStyles } from "@material-ui/core";

import Title from "../../../uiKit/Title/Title";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { MailMan } from '../../../uiKit/assets/svg/mailMan'
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomCheckbox from "../../../uiKit/CustomCheckbox/customCheckbox";

import { setDonate } from "../../../redux/state/commonSlice";

const useStyles = makeStyles(() => ({
    paper: {
        '&.MuiPaper-rounded': {
            borderRadius: '12px'
        }
    }
}))

const DonateButton = () => {

    const dispatch = useDispatch();
    const dialogClasses = useStyles();
    const [donationAmount, setDonationAmount] = useState(0);
    const [feeConfirm, setFeeConfirm] = useState(true);

    const open = useSelector(state => state.common.donateModal, shallowEqual);

    const closeHandler = () => {
        dispatch(setDonate(false));
    }

    const openDonationHandler = () => {
        dispatch(setDonate(true));
    }

    const donateHandler = () => { }

    const donationAmountHandler = (event) => {
        const value = event.target.value;
        setDonationAmount(value);
    }

    const feeConfirmHandler = () => {
        setFeeConfirm(prev => !prev)
    }

    return (
        <>
            <CustomButton title={"Donate to All"} onClick={openDonationHandler} disabled/>
            <Dialog
                open={open}
                onClose={closeHandler}
                disableScrollLock={true}
                classes={dialogClasses}
            >
                <div className='feedback page' >
                    <div className='feedbackContainer'>
                        <div className='feedbackImageContainer'>
                            <MailMan />
                        </div>
                        <div className='description'>
                            <Title
                                text={"Donate All"}
                                fontWeight={600}
                                lineHeight={40}
                                fontSize={32}
                                style={{ marginBottom: '16px' }}
                            />
                            <Title
                                text={"Your contribution will be evenly distributed across all active campaigns, helping multiple causes at once with a single donation."}
                                lineHeight={20}
                                fontSize={14}
                                style={{ marginBottom: '16px' }}
                                fontWeight={400}
                            />
                            <CustomInput
                                label={"Donation Amount"}
                                onChange={donationAmountHandler}
                                value={donationAmount}
                                style={{ marginBottom: '16px' }}
                            />
                            <CustomCheckbox
                                label={"I would like to cover the processing fees to this donation "}
                                checked={feeConfirm}
                                onChange={feeConfirmHandler}
                                style={{ marginBottom: '16px' }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '24px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CustomButton
                            onClick={donateHandler}
                            title={"Confirm"}
                        />
                        <CustomButton
                            onClick={closeHandler}
                            title='Decline'
                            isWhite
                            style={{ marginTop: '16px' }}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DonateButton;