import React, { useCallback, useState } from "react";
import './campaign.scss';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header/header";
import { useHistory, useParams } from "react-router";
import Title from "../../../uiKit/Title/Title";
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { transferFromOurBalance } from "../../../redux/state/paymentSlice";
import { MailMan } from "../../../uiKit/assets/svg/mailMan";
import { unwrapResult } from "@reduxjs/toolkit";
import { onlyNumbers } from "../../../helpers/validation";
import { toast } from "react-toastify";
import CustomDialog from "../../../uiKit/CustomDialog/customDialog";

const Transfer = () => {

    const isLoading = useSelector(state => state.payment.isLoading.transferFromOurBalance, shallowEqual);

    const [amount, setAmount] = useState(0);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [error, setError] = useState('');

    const history = useHistory();
    const dispatch = useDispatch();

    const { campaignId, campaignName } = useParams();

    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const communityBalance = useSelector(state => state.community.community.ourBalance, shallowEqual);

    const transferToCampaign = useCallback(() => {
        const data = {
            outgoingCommunityId: communityId,
            incomingCamapaignId: campaignId,
            amount: parseInt(amount)
        }
        dispatch(transferFromOurBalance({ communityId, campaignId, data }))
            .then(unwrapResult)
            .then(() => history.push(`/campaigns/campaign/active/${communityId}/${campaignId}`))
            .catch(({ title }) => {
                setConfirmOpen(false);
                toast.info(`Transfer failed: ${title}`);
            })
    }, [communityId, campaignId, amount, history, dispatch])

    const confirmHandler = () => {
        if (!amount) {
            setError('Invalid amount');
        } else
            if (amount > communityBalance) {
                setError('Not enough money on Our Balance');
            } else {
                setConfirmOpen(true);
            }
    }

    const onChangeHandler = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            setAmount(e.target.value);
            setError('');
        } else {
            setError('Only numbers allowed');
        }
    }

    return (
        <>
            <Header title='Campaign page' onClick={() => history.push(`/campaigns/campaign/active/${communityId}/${campaignId}`)} />
            <div className='boxShadow donationWrapper'>
                <Title
                    text='Transfer amount:'
                    fontSize={32}
                    lineHeight={40}
                    style={{
                        marginBottom: '16px'
                    }}
                />
                <CustomInput
                    description={`Total on Our Balance $${communityBalance.toLocaleString()}`}
                    onChange={onChangeHandler}
                    value={!!amount ? amount : ''}
                    style={{
                        marginBottom: '80px'
                    }}
                    error={!!error}
                    errorMessage={error}
                />

                <CustomButton
                    title='Transfer from Bank'
                    onClick={confirmHandler}
                    disabled={!communityBalance}
                />

            </div>
            
            <CustomDialog
                confirmOpen={confirmOpen}
                onClose={() => { setConfirmOpen(false) }}
                Image={MailMan}
                mainButtonTitle='Confirm'
                mainButtonAction={transferToCampaign}
                isLoading={isLoading}
                secondButton={true}
                title={`You're going transfer $${parseInt(amount).toLocaleString()} to ${campaignName} from our balance.`}
                secondDescription='Confirm your action, please'
            />
        </>
    )
}

export default Transfer;