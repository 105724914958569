import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { useHistory } from "react-router";
import { blue500, gray800 } from "../../../uiKit/assets/colors/colors";
import { useStyle } from "../../../uiKit/CustomTable/customTable";
import Title from "../../../uiKit/Title/Title";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clearCommunity, setCommunityId } from "../../../redux/state/communitySlice";
import { clearCampaigns, clearDrafts, clearSuggestions } from "../../../redux/state/campaignSlice";
import { clearAdmins } from "../../../redux/state/memberSlice";
import { toast } from "react-toastify";
import { clearNotifications } from "../../../redux/state/notificationSlice";

const RowRenderer = ({ items }) => {

    const cellClasses = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();

    const currentCommunities = useSelector(state => state.community.memberCommunities, shallowEqual);

    const communityClickHandler = async (communityId) => {
        if (!!currentCommunities && !currentCommunities.some(community => community.id === communityId)) {
            toast.info('You are not member of this community anymore');
            return;
        }

        dispatch(clearCommunity());
        dispatch(clearCampaigns());
        dispatch(clearAdmins());
        dispatch(clearDrafts());
        dispatch(clearSuggestions());
        dispatch(clearNotifications());
        dispatch(setCommunityId({ communityId }));
        window.localStorage.setItem('communityId', communityId);
        history.push('/campaigns');
    }

    const campaignClickHandler = (campaign, communityId) => {
        if (!!currentCommunities && !currentCommunities.some(community => community.id === communityId)) {
            toast.info('You are not member of this community anymore');
            return;
        }

        communityClickHandler(communityId)
            .then(() => {

                if (!campaign) {
                    history.push('/our-balance');
                    return;
                }

                if (campaign.status === 'launched') {
                    history.push(`/campaigns/campaign/active/${communityId}/${campaign.id}`);
                    return;
                }

                if (campaign.status === 'completed' || campaign.status === 'stopped') {
                    history.push(`/campaigns/campaign/completed/${campaign.id}`);
                    return;
                }
            })
    }

    return (
        items.map((item, index) => (
            <TableRow key={index}>
                <TableCell classes={cellClasses}>
                </TableCell>
                <TableCell classes={cellClasses} onClick={() => campaignClickHandler(item.to.campaign, item.to.community.id)} >
                    <Title
                        text={!!item.to.campaign ? item.to.campaign.name : 'Our balance'}
                        fontWeight={400}
                        color={blue500}
                        style={{
                            marginLeft: '20px',
                            marginRight: '8px',
                            cursor: 'pointer'
                        }} />
                </TableCell>
                <TableCell classes={cellClasses} onClick={() => communityClickHandler(item.to.community.id)}>
                    <Title
                        text={item.to.community.name}
                        fontWeight={400}
                        color={blue500}
                        style={{ marginRight: '8px', cursor: 'pointer', }}
                    />
                </TableCell>
                <TableCell classes={cellClasses}>
                    <Title
                        text={`${moment(item.date).format('MM/DD/YYYY')} ${moment(item.date).format('h:mm A')}`}
                        fontWeight={400}
                        style={{ marginRight: '8px' }}
                        color={gray800}
                    />
                </TableCell>
                <TableCell classes={cellClasses}>
                    <Title
                        text={`$${item.amount.toLocaleString()}`}
                        fontWeight={400}
                        style={{ marginRight: '8px' }}
                        color={gray800}
                    />
                </TableCell>
            </TableRow>
        ))
    )
}

export default RowRenderer;