import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../redux/API/main";
import {
    logout,
    parseToken,
} from "../../redux/state/accountSlice";
import { clearCommunity, setCommunityId } from "../../redux/state/communitySlice";
import { addMember } from "../../redux/state/memberSlice";

export const ProtectedRoutes = ({ children }) => {

    const token = window.localStorage.getItem('token');

    if (!!token) {
        const parsedToken = JSON.parse(token);
        API.setToken(parsedToken.access_token);
    }

    return !!token ? children : <Redirect to='/login' />
}

export const LoginChecker = ({ children }) => {

    const token = window.localStorage.getItem('token');

    return !token ? children : <Redirect to={'/campaigns'} />;

}

export const AdminChecker = ({ children }) => {

    const isAdmin = JSON.parse(window.localStorage.getItem('isAdmin'));

    return isAdmin ? children : <Redirect to='/campaigns' />;
}

export const ConfirmInvitation = () => {


    const user = useSelector(state => state.account.userByParseToken, shallowEqual);

    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const userId = window.localStorage.getItem('userId');

        const invitationToken = encodeURIComponent(new URLSearchParams(search).get('token'));
        if (!!invitationToken && !user.id) {
            dispatch(parseToken(invitationToken))
        }

        if (!!user.id && !!userId) {
            if (user.userId === userId) {
                dispatch(clearCommunity());
                window.localStorage.setItem('communityId', user.communityId);
                dispatch(setCommunityId({ communityId: user.communityId }));
                history.push('/campaigns');
                return;
            } else {
                dispatch(logout());
                if (user.isVerified) {
                    history.push(`/login/${invitationToken}`);
                    return;
                } else {
                    history.push(`/welcome${search}&type=create`)
                    return;
                }
            }

        }

        if (!!user.id && user.isVerified && !userId) {
            history.push(`/login/${invitationToken}`);
            return;
        }

        if (!!user.id && !user.isVerified && !userId) {
            if (!!user.email) {
                history.push(`/welcome${search}&type=create`)
            } else {
                history.push(`/welcome${search}&type=new`)
            }

        }
    }, [user, search, history, dispatch])

    return null;
}

export const CommunityInvitation = () => {

    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const token = window.localStorage.getItem('token');

    useEffect(() => {
        if (!!token) {
            const invitationToken = encodeURIComponent(new URLSearchParams(search).get('token'));
            dispatch(parseToken(invitationToken))
                .then(unwrapResult)
                .then(response => {
                    dispatch(addMember(response.user.communityId))
                        .then(unwrapResult)
                        .then(() => {
                            dispatch(setCommunityId({ communityId: response.user.communityId }));
                            window.localStorage.setItem('communityId', response.user.communityId);
                            history.push('/campaigns');
                        })
                        .catch(() => {
                            toast.info('Something went wrong, please login.')
                            history.push('/login');
                        })
                })
        } else {
            history.push(`/welcome${search}&type=new`)
        }

    }, [token, search, history, dispatch])

    return null;
}

export const ApproveCommunity = () => {

    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()

    const token = window.localStorage.getItem('token');

    useEffect(() => {
        if (!!token) {
            const communityId = new URLSearchParams(search).get('communityId');
            dispatch(clearCommunity());
            dispatch(setCommunityId({ communityId }))
            window.localStorage.setItem('communityId', communityId);
            history.push('/members/add-members');
        } else {
            history.push(`/login${search}`);
        }
    }, [token, search, history, dispatch])

    return null;
}

export const RedirectActiveCampaign = () => {

    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const token = window.localStorage.getItem('token');

    const communityId = new URLSearchParams(search).get('communityId');
    const campaignId = new URLSearchParams(search).get('campaignId');

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            if (!!token) {
                dispatch(clearCommunity());
                dispatch(setCommunityId({ communityId }))
                window.localStorage.setItem('communityId', communityId);
                history.push(`/campaigns/campaign/active/${communityId}/${campaignId}`);
            } else {
                history.push({
                    pathname: `/`,
                    search: `?pageType=campaign&communityId=${communityId}&campaignId=${campaignId}`
                });
            }
        }
    }, [token, history, campaignId, communityId, dispatch])

    return null;
}