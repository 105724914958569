import React, { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
    onlyNumbers,
    validateCampaignName,
    validateEmail,
    validateName
} from "../../../helpers/validation";

import Header from "../../../components/Header/header";

import { DollarSign } from "../../../uiKit/assets/svg/icons";
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomSelect from "../../../uiKit/CustomSelect/CustomSelect";
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomPhoneInput from "../../../uiKit/Input/CustomPhoneInput";
import Title from "../../../uiKit/Title/Title";

import { getCampaignTypes, getCampaignUrgencyTypes, suggestCampaignDraft } from "../../../redux/state/campaignSlice";

const SuggestCampaign = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.campaign.isLoading.suggestions, shallowEqual);

    const types = useSelector(state => state.campaign.types, shallowEqual);
    const urgencyTypes = useSelector(state => state.campaign.urgencyTypes, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);
    const isLoadingTypes = useSelector(state => state.campaign.isLoading.getCampaignTypes, shallowEqual);
    const isLoadingUrgencyTypes = useSelector(state => state.campaign.isLoading.getCampaignUrgencyTypes, shallowEqual);

    const [campaign, setCampaign] = useState({
        name: null,
        campaignTypeId: 0,
        description: null,
        amount: 0,
        recipient: {
            fullName: null,
            email: null,
            phoneNumber: null,
        },
        campaignUrgencyType: 1,
    });

    const [titleError, setTitleError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth))
    }, [screenWidth])

    useEffect(() => {
        if (!types) {
            dispatch(getCampaignTypes())
        }
    }, [types, dispatch])

    useEffect(() => {
        if (!urgencyTypes) {
            dispatch(getCampaignUrgencyTypes())
        }
    }, [urgencyTypes, dispatch])

    const titleChangeHandler = (e) => {
        setCampaign(prev => ({ ...prev, name: e.target.value }));
        if (validateCampaignName(e.target.value)) {
            setTitleError('');
        } else {
            if (!e.target.value.length) {
                setTitleError('Required');
            } else {
                setTitleError('Invalid campaign name');
            }
        }
    }

    const amountChangeHandler = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            setCampaign(prev => ({ ...prev, amount: !!parseInt(value) ? parseInt(value) : 0 }));
            setAmountError('');
        } else {
            if (!value) {
                setAmountError('Required')
            } else {
                setAmountError('Invalid amount')
            }
        }
    }

    const validateTitle = () => {
        if (!campaign.name) {
            setTitleError('Required')
        }
    }

    const validateAmount = () => {
        if (!campaign.amount) {
            setAmountError('Required')
        }
    }

    const validateFullName = () => {
        if (!campaign.recipient.fullName) {
            setNameError('Required')
        }
    }

    const validateEmailInput = () => {
        if (!campaign.recipient.email) {
            setEmailError('Required')
        }
    }

    const validatePhoneNumber = () => {
        if (!!campaign.recipient.phoneNumber && !isPossiblePhoneNumber(campaign.recipient.phoneNumber)) {
            setPhoneNumberError('Invalid phone number')
        } else {
            setPhoneNumberError('');
        }
    }

    const fullNameHandler = (e) => {
        const value = e.target.value;
        setCampaign(prev => ({ ...prev, recipient: { ...prev.recipient, fullName: value } }));

        if (validateName(value)) {
            setNameError('')
        } else {
            setNameError('Invalid full name')
        }
    }

    const suggestCampaignHandler = () => {
        const body = {
            data: campaign,
            communityId
        }

        ReactGA.event('suggest_campaign_create');

        dispatch(suggestCampaignDraft(body))
            .then(unwrapResult)
            .then(() => {
                toast.info('Sent');
                history.push('/campaigns')
            }).catch(({ title }) => {
                toast.info(title);
                if (!campaign.name) setTitleError('Required');
                if (!campaign.recipient.email) setEmailError('Required');
                if (!campaign.recipient.fullName) setNameError('Required');
            })
    }

    const emailHandler = (e) => {
        const value = e.target.value;
        setCampaign(prev => ({ ...prev, recipient: { ...prev.recipient, email: value } }));

        if (validateEmail(value)) {
            setEmailError('')
        } else {
            setEmailError('Invalid email address')
        }
    }

    return (
        <>
            <Header title='Campaigns' onClick={() => history.push('/campaigns')} />
            <div className='campaignTitle'>
                <Title
                    text="Suggest a campaign"
                    fontSize={32}
                    lineHeight={40}
                />
            </div>
            <div className='titleInputWrapper'>
                <div className="content">
                    <div className='inputGroup'>
                        <CustomInput
                            label='Campaign title'
                            description="Do not include the recipient's name in the campaign title, please"
                            onChange={titleChangeHandler}
                            error={!!titleError}
                            errorMessage={titleError}
                            outFocus={validateTitle}
                            onFocus={() => setTitleError('')}
                            value={campaign.name}
                        />
                        <CustomSelect
                            label='Type of need'
                            data={types}
                            onChange={(e) => setCampaign(prev => ({ ...prev, campaignTypeId: parseInt(e.target.value) }))}
                            menuWidth={screenWidth > 900 ? 320 : 368}
                            value={campaign.campaignTypeId}
                            menuPosition={10}
                            loading={isLoadingTypes}
                        />
                    </div>
                    <div className='inputGroup middleInputs'>
                        <CustomSelect
                            label='Campaign Type (Optional)'
                            data={urgencyTypes}
                            onChange={(e) => setCampaign(prev => ({ ...prev, campaignUrgencyType: e.target.value }))}
                            menuWidth={screenWidth > 900 ? 320 : 368}
                            value={campaign.campaignUrgencyType}
                            menuPosition={10}
                            loading={isLoadingUrgencyTypes}
                        />
                        <CustomInput
                            label='Amount'
                            EndAdornment={DollarSign}
                            onChange={amountChangeHandler}
                            value={campaign.amount}
                            error={!!amountError}
                            errorMessage={amountError}
                            outFocus={validateAmount}
                            onFocus={() => setAmountError('')}
                            maxLength={12}
                        />
                    </div>
                    <CustomInput
                        multiline
                        label='Description (optional)'
                        description="Do not include the recipient's name in the campaign description, please"
                        onChange={(e) => setCampaign(prev => ({ ...prev, description: e.target.value }))}
                        height={56}
                        maxLength={2000}
                        value={campaign.description}
                        style={screenWidth < 900 && { marginBottom: '24px' }}

                    />
                    <div className='divider' />
                    <div className="inputGroup middleInputs">
                        <CustomInput
                            label="Recipient's full name"
                            onChange={fullNameHandler}
                            error={!!nameError}
                            errorMessage={nameError}
                            outFocus={validateFullName}
                            onFocus={() => setNameError('')}
                            value={campaign.recipient.fullName}
                        />
                        <CustomInput
                            label="Recipient's email"
                            placeholder='Email'
                            type='email'
                            onChange={emailHandler}
                            value={campaign.recipient.email}
                            outFocus={validateEmailInput}
                            error={!!emailError}
                            errorMessage={emailError}
                        />
                    </div>
                    <div className="inputGroup middleInputs">
                        <CustomPhoneInput
                            label="Phone number (optional)"
                            placeholder='Phone'
                            onChange={(value) => setCampaign(prev => ({ ...prev, recipient: { ...prev.recipient, phoneNumber: value } }))}
                            value={campaign.recipient.phoneNumber}
                            outFocus={validatePhoneNumber}
                            error={!!phoneNumberError}
                            errorMessage={phoneNumberError}
                        />
                    </div>
                    <div className='divider' style={{ marginTop: 0 }} />
                    <CustomButton
                        title='Suggest Campaign'
                        onClick={suggestCampaignHandler}
                        disabled={
                            titleError ||
                            amountError ||
                            nameError ||
                            emailError ||
                            phoneNumberError ||
                            isLoading
                        }
                        loading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default SuggestCampaign;