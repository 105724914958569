import { FetchAPI } from "./main";

export const CampaignAPI = {
    getCampaignUrgencyTypes: () => FetchAPI.get('/community/campaigns/urgency-types'),
    getCampaignTypes: () => FetchAPI.get('/community/campaigns/types'),
    createCampaign: (
        communityId,
        data
    ) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns`, data),
    //pagination
    getAllActiveCampaigns: (data) => {

        const {
            communityId,
            page,
            pageSize,
            campaignTypeId,
            searchString,
            state,
            orderBy,
            direction,
        } = data;
        const query = new URLSearchParams();

        if (!!communityId) query.set('communityId', communityId);
        if (!!campaignTypeId) query.set('campaignTypeId', campaignTypeId);
        if (!!searchString) query.set('searchString', searchString);
        if (!!state) query.set('state', state);
        if (!!orderBy) query.set('orderBy', orderBy);
        if (!!direction) query.set('direction', direction);

        return FetchAPI.getJson(`/community/campaigns/${pageSize}/${page}?${query.toString()}`)
    },
    getActiveCampaigns: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/active/${pageSize}/${page}`),
    getActiveCampaignsPublic: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/active/${pageSize}/${page}`),
    getCompletedCampaigns: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/completed/${pageSize}/${page}`),
    //campaignId
    getCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/${campaignId}`),
    getActiveCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/active/${campaignId}`),
    updateActiveCampaign: ({
        communityId,
        campaignId,
        data,
    }) => FetchAPI.putJson(`/community/communities/${communityId}/campaigns/active/${campaignId}`, data),
    getCampaignPublic: ({
        communityId,
        campaignId
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/${campaignId}`),
    getStoppedCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/stopped/${campaignId}`),
    getCompletedCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/completed/${campaignId}`),
    getCompletedCampaignPublic: ({
        communityId,
        campaignId
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/completed/${campaignId}`),
    stopCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/${campaignId}/stop`),
}