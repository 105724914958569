import './CustomSelect.scss';
import { makeStyles } from '@material-ui/core/styles';

import {
    red900,
    gray010,
    gray500,
    gray800,
    blue050,
    gray300
} from '../assets/colors/colors';

import { Arrow } from '../assets/svg/icons';
import { InputBase, MenuItem, Select } from "@mui/material";
import Title from '../Title/Title';
import CustomInputInfo from '../Input/CustomInputInfo';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        border: "1px solid",
        borderRadius: "10px !important",
        textTransform: "none",
        background: gray010,
        color: gray800,
        borderColor: gray300,
        height: "40px",
        padding: "0 20px",
        fontFamily: 'Poppins',
        borderBottom: `1px solid ${gray500}`,
        cursor: 'pointer',
        lineHeight: '20px',
        marginBottom: '28px'
    },
    error: {
        borderBottom: `1px solid ${red900}`,
        borderRadius: '4px'
    },
    disabled: {
        border: "1px solid",
        borderRadius: "10px",
        textTransform: "none",
        background: blue050,
        color: gray010,
        borderColor: blue050,
    },
    input: {
        padding: '10px 0 !important',
        minHeight: '0 !important',
    }
}));

const useItemStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        textTransform: "none",
        background: gray010,
        color: gray800,
        borderColor: gray300,
        height: "40px",
        padding: "0 20px",
        fontFamily: 'Poppins'
    },
    selected: {
        backgroundColor: `${blue050} !important`
    }
}));

const ArrowComponent = ({ className, ...rest }) => (
    <div
        {...rest}
        className={className}
        style={{
            right: '20px',
            lineHeight: '14px'
        }}
    >
        <Arrow />
    </div>
);

const CustomSelect = ({
    label,
    value = '',
    data,
    placeholder,
    onChange,
    onFocus,
    error,
    errorMessage,
    description,
    menuWidth = 368,
    outFocus,
    menuPosition = 0,
    loading = false,
    style
}) => {

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
                width: menuWidth,
                marginTop: menuPosition,
                marginLeft: 0,
                paddingTop: 0,

            },
        },
        getcontentanchorel: null,
    };

    const selectClasses = useStyles();
    const itemClasses = useItemStyles();

    return (
        <div className="customInput" style={{ ...style }}>
            {!!label ? <div className="label">{label}</div> : null}
            <Select
                value={value}
                IconComponent={ArrowComponent}
                error={!!errorMessage}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={outFocus}
                renderValue={(selected) => {

                    if (selected === 0) {
                        return <Title fontWeight={400} text={placeholder} />;
                    }
                    if (!!data) {

                        const label = data.find(item => item.id === parseInt(selected));
                        const labelString = data.find(item => item.id === selected)
                        return (
                            !!label ? (
                                <Title
                                    text={label.name}
                                    fontWeight={400}
                                    color={gray800}
                                />
                            ) : (
                                !!labelString && (
                                    <Title
                                        text={labelString.name}
                                        fontWeight={400}
                                        color={gray800}
                                    />
                                )
                            )
                        );
                    }
                }}
                input={<InputBase
                    classes={selectClasses}
                    endAdornment={
                        loading ? (
                            <CircularProgress
                                color="inherit"
                                size={20}
                                style={{ marginRight: '30px' }}
                            />
                        ) : null
                    }
                />}
                MenuProps={MenuProps}
            >
                {
                    !!data && data.map((item, index) => (
                        <MenuItem value={item.id} key={index} style={{
                            justifyContent: 'flex-start',
                            paddingLeft: '16px'
                        }}
                            classes={itemClasses}
                        >
                            <Title
                                text={item.name}
                                fontWeight={400}
                                color={gray800}
                            />
                        </MenuItem>
                    ))
                }
            </Select>
            <CustomInputInfo
                error={error}
                errorMessage={errorMessage}
                description={description}
            />
        </div >
    );
}

export default CustomSelect;
