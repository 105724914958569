import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../../uiKit/CustomTable/customTable";
import CustomMobilePagination from "../../../../uiKit/CustomTable/mobilePagination";
import RowRenderer from '../../campaignRow';
import RecentDonationsList from "../../../OurBalance/RecentDonations/recentDonationsList";
import { getCampaignDonations } from "../../../../redux/state/campaignSlice";
import { useLocation, useParams } from "react-router-dom";
import RowRendererUser from "../../campaignRowUser";
import NoDonations from "./noDonations";


const adminHeader = [
    { name: '' },
    { name: 'Name' },
    { name: 'Operation time' },
    { name: 'Amount' },
    { name: 'Anonymous' },
];

const userHeader = [
    { name: '' },
    { name: 'Name' },
    { name: 'Amount' },
]

const RecentDonationsTable = ({ isAdmin, communityId, hasNoData = true }) => {

    const { campaignId } = useParams();
    const { pathname, search } = useLocation();

    const [items, setItems] = useState(2);
    const [paginationDataDonations, setPaginationDataDonations] = useState(null);

    const donations = useSelector(state => state.campaign.campaignDonations, shallowEqual);
    const isLoading = useSelector(state => state.campaign.isLoading.campaignDonations, shallowEqual);

    const dispatch = useDispatch();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        if (!!paginationDataDonations && !!communityId && !!campaignId) {
            dispatch(getCampaignDonations({
                ...paginationDataDonations,
                communityId,
                campaignId,
                isAdmin
            }))
        }
    }, [
        paginationDataDonations,
        communityId,
        campaignId,
        isAdmin,
        dispatch,
    ])


    useEffect(() => {
        const query = new URLSearchParams(search);
        const page = parseInt(query.get('page') ?? 1);

        setPaginationDataDonations({
            pageSize: 8,
            page
        })
    }, [search])

    return (
        <>
            {
                !!donations && !!donations.items && !!donations.items.length ? (
                    <div className='donationTable'>
                        <CustomTable
                            RowRenderer={isAdmin ? RowRenderer : RowRendererUser}
                            headerArray={isAdmin ? adminHeader : userHeader}
                            data={donations}
                            isLoading={isLoading}
                            url={`/campaigns/campaign/active/${communityId}/${campaignId}`}
                            setPaginationData={setPaginationDataDonations}
                        />
                    </div>
                ) : (
                    hasNoData && (
                        <div className='displayNone'>
                            <NoDonations campaignId={campaignId} />
                        </div>
                    )
                )
            }
            {
                screenWidth < 900 && (
                    <div className='donationListWrapper'>
                        <div className='donationList'>
                            {
                                !!donations.items && <RecentDonationsList data={donations.items} items={items} />
                            }
                        </div>
                        <CustomMobilePagination
                            data={donations}
                            tableUrl={pathname}
                            count={items}
                            setItems={setItems}
                            marginTop={16}
                            NoData={hasNoData ? () => <NoDonations campaignId={campaignId} /> : () => <></>}
                        />
                    </div>
                )
            }
        </>
    )
}

export default RecentDonationsTable;