// 1 - Regular
// 2 - Urgent
// 3 - Recurring

import { gray010, gray900, red800 } from "../../../../uiKit/assets/colors/colors";
import { Urgent, Recurring } from "../../../../uiKit/assets/svg/icons"
import Title from "../../../../uiKit/Title/Title"

const Badge = ({ campaignUrgencyType }) => {

    return (
        <div style={{
            display: campaignUrgencyType.id === 1 ? 'none' : 'flex',
            gap: '6px',
            alignItems: 'center',
            position: 'absolute',
            padding: '3px 8px',
            left: 8,
            bottom: 12,
            borderRadius: '20px',
            backgroundColor: campaignUrgencyType.id === 2 ? red800 : gray010,
            height: '22px',
            width: '94px',
        }}>
            {
                campaignUrgencyType.id === 2 && <div><Urgent /></div>
            }
            {
                campaignUrgencyType.id === 1 && <Recurring />
            }
            <Title
                text={campaignUrgencyType.name}
                color={campaignUrgencyType.id === 2 ? gray010: gray900}
                fontSize={12}
                fontWeight={500}
                lineHeight={14}
                style={{ overflow: 'visible' }}
            />
        </div>
    )
}
export default Badge;