import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import CustomButton from "../../../../uiKit/Button/CustomButton";
import CampaignMessages from "../../CreateCampaign/HelperComponents/campaignMessages";
import CampaignsInfos from "../../CreateCampaign/HelperComponents/campaignInfos";
import Header from "../../../../components/Header/header";

import {
    getActiveCampaign,
    updateActiveCampaign,
} from "../../../../redux/state/campaignSlice";

const ActiveCampaignEdit = () => {

    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoadingMessages = useSelector(state => state.campaign.isLoading.getMessages, shallowEqual);
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const [messages, setMessages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState({
        name: null,
        campaignTypeId: 0,
        recipientId: null,
        excludedMembers: [],
        amount: 0,
        description: null,
        messages: null,
        campaignUrgencyType: 1,
    });
    const [excludedMembers, setExcludedMembers] = useState([]);

    useEffect(() => {

        if (!communityId || !campaignId) return;
        setIsLoading(true);
        dispatch(getActiveCampaign({ communityId, campaignId }))
            .then(unwrapResult)
            .then((response) => {
                const ids = response.excludedMembers.map(member => member.userId)
                const campaignUrgencyType = response.campaignUrgencyType.id;
                setExcludedMembers(response.excludedMembers);
                setCampaign({ ...response, excludedMembers: ids, campaignUrgencyType,  })
            })
            .catch(({ title }) => toast.info(title))
            .finally(() => setIsLoading(false))
    }, [communityId, campaignId, dispatch])

    const updateMessages = useCallback((newMessages) => {
        setMessages(newMessages)
    }, [])

    const campaignInfoHandler = useCallback(() => {
        if (!campaignId) return;
        setIsLoading(true);
        dispatch(updateActiveCampaign({
            campaignId,
            communityId,
            data: {
                ...campaign,
                communityId,
                messages,
            }
        })).then(unwrapResult)
            .then(() => {
                toast.info('Success');
                history.goBack();
            })
            .catch(({ title }) => toast.info(title))
            .finally(() => setIsLoading(false))
    }, [campaignId, communityId, campaign, messages, dispatch, history])

    return (
        <>
            <Header title={campaign.name} onClick={() => history.goBack()} />
            <div className='titleInputWrapper'>
                <CampaignsInfos
                    campaign={campaign}
                    setCampaign={setCampaign}
                    excluded={excludedMembers}
                />
                <div className='divider' />
                <CampaignMessages
                    messages={campaign.messages}
                    updateMessages={updateMessages}
                    isLoading={isLoadingMessages}
                />
                <div className='divider' />
                <div className='createCampaignButtons'>
                    <CustomButton
                        title={'Update campaign info'}
                        onClick={campaignInfoHandler}
                        loading={isLoading}
                    />
                    <CustomButton
                        title='Cancel'
                        isWhite
                        onClick={() => history.goBack()}
                        classNames='margin16to0'
                    />
                </div>
            </div>
        </>
    )

}

export default ActiveCampaignEdit;