import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import Title from "../../../uiKit/Title/Title";
import { blue500 } from "../../../uiKit/assets/colors/colors";
import Donate from '../../../uiKit/assets/svg/donate.svg';
import DonateButton from "./donateButton";

const Support = () => {

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    return (
        <div className="aboutGradient" >
            <div className="background supportWrapper">
                <div className="supportMain">
                    <Title
                        text="SUPPORT THE ONES WHO NEED IT"
                        color={blue500}
                        lineHeight={18}
                        style={{ marginBottom: '16px', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => window.scrollTo(0, 0)}
                    />
                    <Title
                        text='Donate to All'
                        fontSize={screenWidth > 900 ? 64 : 48}
                        lineHeight={screenWidth > 900 ? 89.6 : 67.2}
                        style={{ marginBottom: '32px' }}
                    />
                    <Title
                        text="With `Donate to All`, your contribution will be evenly 
                        distributed across all active campaigns, helping multiple causes at once with a single donation."
                        fontWeight={300}
                        fontSize={18}
                        lineHeight={25.2}
                    />
                    <DonateButton />
                    <div className="grayBlueCircle" />
                    <div className="yellowCircle" />
                </div>
                <div className="supportImage">
                    <img alt='' src={Donate} style={{ width: '100%', height: '100%' }} />
                    <div className="blueCircle" />

                </div>
            </div>
        </div >
    )

}
export default Support;