import React, { useState } from 'react';
import './ProgressBar.scss';
import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';
import Title from '../Title/Title';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaignBalance } from '../../redux/state/paymentSlice';
import { gray300, green100 } from '../assets/colors/colors';
import { unwrapResult } from '@reduxjs/toolkit';
import Loading from '../Loading/loading';

const ProgressBar = ({
  title,
  target,
  color,
  style,
  campaignId,
  communityId,
  clickable = false,
  onClick,
  className = 'progressWrapper',
}) => {

  const useStyles = makeStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 4,
      margin: '12px 0'
    },
    colorPrimary: {
      backgroundColor: gray300,
    },
    bar: {
      borderRadius: 4,
      backgroundColor: color,
    }
  }));

  const classes = useStyles();

  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  const [isMounted, setIsMounted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsMounted(true);
    if (!!communityId && !!campaignId && isMounted) {
      dispatch(getCampaignBalance({ communityId, campaignId }))
        .then(unwrapResult)
        .then(response => {
          const value = response.campaignBalance.balance;
          setBalance(value);
          const progress = value / target * 100;
          if (progress > 100) {
            setProgressValue(100);
          } else {
            setProgressValue(progress);
          }
        })
        .catch(({ title }) => {
          console.error(title);
        })
      setIsLoading(false);
    }

    return () => setIsMounted(false);
  }, [campaignId, communityId, target, isMounted, dispatch])

  return (
    <div
      className={className}
      style={{ ...style, cursor: clickable ? 'pointer' : 'default' }}
      onClick={clickable ? onClick : () => { }}
      key={campaignId}
    >
      <Title
        text={title}
        fontSize={16}
      />
      <LinearProgress classes={classes} variant="determinate" value={progressValue} />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex'
        }}>
          <Title text={`$${balance.toLocaleString()}`} />
          <Title
            text={`collected of $${target.toLocaleString()}`}
            fontWeight={400}
            style={{
              marginLeft: '4px'
            }}
          />
        </div>
        <div>
          {
            balance - target > 0 ? (
              <Title
                text={`+${(balance - target).toLocaleString()}`}
                color={green100}
              />
            ) : null
          }

        </div>
      </div>
      {
        isLoading && <Loading />
      }
    </div>
  );
}

export default ProgressBar;