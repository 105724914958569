import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialPaymentState = {
    isLoading: {
        getUserDonations: false,
        getCommunityDonations: false,
        getCommunityTransfers: false,
        transferFromOurBalance: false,
        ourBalancePaymentIntent: false,
        campaignPaymentIntent: false,
        subscriptions: false,
        updatePaymentSettings: false,
        getReceivingMethod: false,
        getPaymentSettings: false,
        getSavedCard: false,
        receivePayPal: false,
        receivePaperCheck: false,
        getTotalDonation: false,
        saveUserCard: false,
        common: false,
    },
    isLoadingCampaignBalance: false,
    communityBalance: {
        id: null,
        balance: 0,
        currency: null
    },
    campaignBalance: {
        id: null,
        balance: 0,
        currency: null
    },
    campaignNetBalance: {
        id: null,
        balance: 0,
        currency: null
    },
    error: null,
    userDonations: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    donations: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    transfers: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    subscriptions: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    transfer: null,
    paymentInfo: {
        clientSecret: null,
        amount: 0,
        fee: 0,
        net: 0,
        donorInfo: {
            email: null,
            isVerified: false
        }
    },
    savedCard: null,
    paymentInfoSavedCard: {
        paymentIntentClientSecret: null,
        paymentIntentId: null,
        paymentMethodId: null,
        amount: 0,
        fee: 0,
        net: 0
    },
    isAnonymous: false,
    receivingMethod: null,
    receivingMethodError: null,
    userTotalDonation: null,
}

export const getTotalDonation = createAsyncThunk(
    '/donation/total',
    async (_, thunkAPI) => {
        const response = await API.PaymentAPI.getTotalDonation();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCampaignBalance = createAsyncThunk(
    '/balance/campaign',
    async ({ communityId, campaignId }, thunkAPI) => {
        const response = await API.PaymentAPI.getCampaignBalance({ communityId, campaignId });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaignBalance: response }
    }
)

export const getCampaignNetBalance = createAsyncThunk(
    '/balance/campaign/net',
    async ({ communityId, campaignId }, thunkAPI) => {
        const response = await API.PaymentAPI.getCampaignNetBalance({ communityId, campaignId });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaignBalance: response }
    }
)

//donations

export const getCommunityDonations = createAsyncThunk(
    '/community/donations',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getCommunityDonations(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { donations: response }
    }
)

export const getUserDonations = createAsyncThunk(
    '/community/user/donations',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getUserDonations(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { donations: response }
    }
)

//transfers

export const getCommunityTransfers = createAsyncThunk(
    '/community/transfers',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getTransfers(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { transfers: response }
    }
)

export const transferFromOurBalance = createAsyncThunk(
    'community/campaign/transfer',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.transferFromOurBalance(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { transfer: response }
    }
)

//stripe

export const saveUserCard = createAsyncThunk(
    '/payment/user/card/save',
    async (_, thunkAPI) => {
        const response = await API.PaymentAPI.savePaymentCard();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getSavedCard = createAsyncThunk(
    '/payment/user/card',
    async (_, thunkAPI) => {
        const response = await API.PaymentAPI.getUserCard();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const ourBalancePaymentIntent = createAsyncThunk(
    'payment/paymentIntent/ourBalance',
    async ({ communityId, body }, thunkAPI) => {
        const response = await API.PaymentAPI.ourBalancePaymentIntent({
            communityId,
            body
        });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const ourBalancePaymentIntentSaved = createAsyncThunk(
    'payment/paymentIntent/saved/ourBalance',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.ourBalancePaymentIntentSavedCard(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const campaignPaymentIntent = createAsyncThunk(
    'payment/paymentIntent/campaign',
    async ({ communityId, campaignId, body }, thunkAPI) => {
        const response = await API.PaymentAPI.campaignPaymentIntent({
            communityId,
            campaignId,
            body
        });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const campaignPaymentIntentSaved = createAsyncThunk(
    'payment/paymentIntent/saved/campaign',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.campaignPaymentIntentSavedCard(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

//stripe-subscription

export const cancelSubscription = createAsyncThunk(
    'payment/subscriptions/cancel',
    async (subscriptionId, thunkAPI) => {
        const response = await API.PaymentAPI.cancelSubscription(subscriptionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSubscriptions = createAsyncThunk(
    'payment/subscriptions/get',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getSubscriptions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { subscriptions: response };
    }
)

//payment-setting

export const getPaymentSettings = createAsyncThunk(
    'payment/user/settings/get',
    async (_, thunkAPI) => {
        const response = await API.PaymentAPI.getPaymentSetting();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response.donateAnonymously;
    }
)


export const updatePaymentSettings = createAsyncThunk(
    'payment/user/settings/update',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.updatePaymentSetting(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getReceivingMethod = createAsyncThunk(
    '/payment/receiving-method',
    async ({ communityId, campaignId }, thunkAPI) => {
        const response = await API.PaymentAPI.getReceivingMethod({ communityId, campaignId });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { receivingMethod: response };
    }
)

export const receivePayPal = createAsyncThunk(
    'payment/receive/paypal',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.receivePayPal(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const receivePaperCheck = createAsyncThunk(
    'payment/receive/paypal',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.receivePaperCheck(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialPaymentState,
    reducers: {
        clearCampaignBalance(state) {
            state.campaignBalance = initialPaymentState.campaignBalance;
        },
        clearSavedCard(state) {
            state.savedCard = initialPaymentState.savedCard;
        },
        clearSubscriptions(state) {
            state.subscriptions = initialPaymentState.subscriptions;
        },
        clearUserDonations(state) {
            state.userDonations = initialPaymentState.userDonations;
        },
        clearTotalDonation(state) {
            state.userTotalDonation = initialPaymentState.userTotalDonation;
        }
    },
    extraReducers: {
        [saveUserCard.pending]: (state) => {
            state.isLoading.saveUserCard = true;
        },
        [saveUserCard.fulfilled]: (state) => {
            state.isLoading.saveUserCard = false;
            state.error = null;
        },
        [saveUserCard.rejected]: (state, action) => {
            state.isLoading.saveUserCard = false;
            state.error = action.payload || action.payload.error;
        },
        [getReceivingMethod.pending]: (state) => {
            state.isLoading.getReceivingMethod = true;
            state.receivingMethod = initialPaymentState.receivingMethod;
        },
        [getReceivingMethod.fulfilled]: (state, action) => {
            state.isLoading.getReceivingMethod = false;
            state.receivingMethod = action.payload.receivingMethod;
            state.receivingMethodError = initialPaymentState.receivingMethodError;
        },
        [getReceivingMethod.rejected]: (state, action) => {
            state.isLoading.getReceivingMethod = false;
            state.receivingMethod = initialPaymentState.receivingMethod;
            state.receivingMethodError = action.payload.title;
        },
        [updatePaymentSettings.pending]: (state) => {
            state.isLoading.updatePaymentSettings = true;
        },
        [updatePaymentSettings.fulfilled]: (state) => {
            state.isLoading.updatePaymentSettings = false;
            state.error = null;
        },
        [updatePaymentSettings.rejected]: (state, action) => {
            state.isLoading.updatePaymentSettings = false;
            state.error = action.payload || action.payload.error;
        },
        [cancelSubscription.pending]: (state) => {
            state.isLoading.subscriptions = true;
        },
        [cancelSubscription.fulfilled]: (state) => {
            state.isLoading.subscriptions = false;
            state.error = null;
        },
        [cancelSubscription.rejected]: (state, action) => {
            state.isLoading.subscriptions = false;
            state.error = action.payload || action.payload.error;
        },
        [getPaymentSettings.pending]: (state) => {
            state.isLoading.getPaymentSettings = true;
        },
        [getPaymentSettings.fulfilled]: (state, action) => {
            state.isLoading.getPaymentSettings = false;
            state.isAnonymous = action.payload;
            state.error = null;
        },
        [getPaymentSettings.rejected]: (state, action) => {
            state.isLoading.getPaymentSettings = false;
            state.error = action.payload || action.payload.error;
        },
        [getSavedCard.pending]: (state) => {
            state.isLoading.getSavedCard = true;
        },
        [getSavedCard.fulfilled]: (state, action) => {
            state.isLoading.getSavedCard = false;
            state.savedCard = action.payload;
            state.error = null;
        },
        [getSavedCard.rejected]: (state, action) => {
            state.isLoading.getSavedCard = false;
            state.error = action.payload || action.payload.error;
        },
        [ourBalancePaymentIntent.pending]: (state) => {
            state.isLoading.ourBalancePaymentIntent = true;
            state.paymentInfo = initialPaymentState.paymentInfo;
        },
        [ourBalancePaymentIntent.fulfilled]: (state, action) => {
            state.isLoading.ourBalancePaymentIntent = false;
            state.paymentInfo = action.payload;
            state.error = null;
        },
        [ourBalancePaymentIntent.rejected]: (state, action) => {
            state.isLoading.ourBalancePaymentIntent = false;
            state.error = action.payload || action.payload.error;
        },
        [campaignPaymentIntent.pending]: (state) => {
            state.isLoading.campaignPaymentIntent = true;
            state.paymentInfo = initialPaymentState.paymentInfo;
        },
        [campaignPaymentIntent.fulfilled]: (state, action) => {
            state.isLoading.campaignPaymentIntent = false;
            state.paymentInfo = action.payload;
            state.error = null;
        },
        [campaignPaymentIntent.rejected]: (state, action) => {
            state.isLoading.campaignPaymentIntent = false;
            state.error = action.payload || action.payload.error;
        },
        [getTotalDonation.pending]: (state) => {
            state.isLoading.getTotalDonation = true;
        },
        [getTotalDonation.fulfilled]: (state, action) => {
            state.isLoading.getTotalDonation = false;
            state.userTotalDonation = action.payload.amount;
            state.error = null;
        },
        [getTotalDonation.rejected]: (state, action) => {
            state.isLoading.getTotalDonation = false;
            state.error = action.payload || action.payload.error;
        },
        [getCampaignBalance.pending]: (state) => {
            state.isLoading.getCampaignBalance = true;
        },
        [getCampaignBalance.fulfilled]: (state, action) => {
            state.isLoading.getCampaignBalance = false;
            state.campaignBalance = action.payload.campaignBalance;
            state.error = null;
        },
        [getCampaignBalance.rejected]: (state, action) => {
            state.isLoading.getCampaignBalance = false;
            state.error = action.payload;
        },
        [getCampaignBalance.pending]: (state) => {
            state.isLoading.getCampaignBalance = true;
        },
        [getCampaignNetBalance.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.campaignBalanceNet = action.payload.campaignBalance;
            state.error = null;
        },
        [getCampaignNetBalance.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.payload;
        },
        [getCampaignNetBalance.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCommunityDonations.fulfilled]: (state, action) => {
            state.isLoading.getCommunityDonations = false;
            state.donations = action.payload.donations;
            state.error = null;
        },
        [getCommunityDonations.rejected]: (state, action) => {
            state.isLoading.getCommunityDonations = false;
            state.error = action.payload;
        },
        [getUserDonations.pending]: (state) => {
            state.isLoading.getUserDonations = true;
        },
        [getUserDonations.fulfilled]: (state, action) => {
            state.isLoading.getUserDonations = false;
            state.userDonations = action.payload.donations;
            state.error = null;
        },
        [getUserDonations.rejected]: (state, action) => {
            state.isLoading.getUserDonations = false;
            state.error = action.payload;
        },
        [transferFromOurBalance.pending]: (state) => {
            state.isLoading.transferFromOurBalance = true;
        },
        [transferFromOurBalance.fulfilled]: (state, action) => {
            state.isLoading.transferFromOurBalance = false;
            state.transfer = action.payload.transfer;
            state.error = null;
        },
        [transferFromOurBalance.rejected]: (state, action) => {
            state.isLoading.transferFromOurBalance = false;
            state.error = action.payload;
        },
        [getCommunityTransfers.pending]: (state) => {
            state.isLoading.getCommunityTransfers = true;
        },
        [getCommunityTransfers.fulfilled]: (state, action) => {
            state.isLoading.getCommunityTransfers = false;
            state.transfers = action.payload.transfers;
            state.error = null;
        },
        [getCommunityTransfers.rejected]: (state, action) => {
            state.isLoading.getCommunityTransfers = false;
            state.error = action.payload;
        },
        [getSubscriptions.pending]: (state) => {
            state.isLoading.subscriptions = true;
        },
        [getSubscriptions.fulfilled]: (state, action) => {
            state.isLoading.subscriptions = false;
            state.subscriptions = action.payload.subscriptions;
            state.error = null;
        },
        [getSubscriptions.rejected]: (state, action) => {
            state.isLoading.subscriptions = false;
            state.error = action.payload;
        },
        [receivePayPal.pending]: (state) => {
            state.isLoading.receivePayPal = true;
        },
        [receivePayPal.fulfilled]: (state) => {
            state.isLoading.receivePayPal = false;
        },
        [receivePayPal.rejected]: (state) => {
            state.isLoading.receivePayPal = false;
        },
        [receivePaperCheck.pending]: (state) => {
            state.isLoading.receivePaperCheck = true;
        },
        [receivePaperCheck.fulfilled]: (state) => {
            state.isLoading.receivePaperCheck = false;
        },
        [receivePaperCheck.rejected]: (state) => {
            state.isLoading.receivePaperCheck = false;
        },
    }
})

export const {
    clearCampaignBalance,
    clearSavedCard,
    clearSubscriptions,
    clearUserDonations,
    clearTotalDonation,
} = paymentSlice.actions;
export default paymentSlice.reducer;