import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { makeStyles, SwipeableDrawer } from "@material-ui/core";

import { blue010 } from "../../../uiKit/assets/colors/colors";
import { Logo } from "../../../uiKit/assets/svg/logo";
import CustomButton from "../../../uiKit/Button/CustomButton";
import Title from "../../../uiKit/Title/Title";
import { Close, MenuIcon } from '../../../uiKit/assets/svg/icons';
import DonateButton from "./donateButton";

const useStyles = makeStyles(() => ({
    paper: {
        width: '100%',
        backgroundColor: blue010,
        maxHeight: 'max-content',
        height: '100%'
    }
}))

const MobileMenuLanding = ({
    scrollToHowItWorks,
    scrollToAbout,
    scrollToContact
}) => {

    const { search, pathname } = useLocation();
    const history = useHistory();

    const drawerClasses = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    // const signUpHandler = useCallback(() => {
    //     ReactGA.event('sign_up_mobile_menu');

    //     history.push('/sign-up')
    // }, [history]);

    const loginHandler = useCallback(() => {
        ReactGA.event('log_in_mobile_menu');

        setIsOpen(false);
        history.push('/login')
    }, [history]);

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_in_mobile_menu');
        setIsOpen(false);

        const query = new URLSearchParams(search);
        query.set("pageType", "FAQ")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [search, history, pathname])

    const onTutorialsClick = useCallback(() => {
        ReactGA.event('tutorials_in_mobile_menu');
        setIsOpen(false);

        const query = new URLSearchParams(search);
        query.set("pageType", "tutorials")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [search, history, pathname])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_in_mobile_menu');
        setIsOpen(false);

        const query = new URLSearchParams(search);
        query.set("pageType", "values")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [search, history, pathname])

    const navigateAllCampaigns = useCallback(() => {
        setIsOpen(false);
        ReactGA.event('all_campaigns_in_mobile_menu');

        const query = new URLSearchParams(search);
        query.set("pageType", "campaigns")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [history, pathname, search])

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <MenuIcon />
            </div>
            <SwipeableDrawer
                anchor='right'
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                classes={drawerClasses}
            >
                <div className='sidebarMobile'>
                    <div
                        className='mobileMenuLanding'
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <div>
                                <Logo />
                            </div>
                            <div onClick={() => setIsOpen(false)} >
                                <Close />
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Title
                                text="How it works"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToHowItWorks();
                                }}
                            />
                            <Title
                                text="About"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToAbout();
                                }}
                            />
                            <Title
                                text="Contact"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToContact();
                                }}
                            />
                            <Title
                                text="All Campaigns"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={navigateAllCampaigns}
                            />
                            <Title
                                text="Our Values"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onOurValuesClick}
                            />
                            <Title
                                text="FAQ's"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onFAQClick}
                            />
                            <Title
                                text="Tutorials"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onTutorialsClick}
                            />

                            <div style={{ marginTop: '8px' }}>
                                <DonateButton />
                                <CustomButton
                                    title='Log in'
                                    onClick={loginHandler}
                                    isWhite
                                    style={{ marginTop: '16px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}

const LandingHeader = ({ referances }) => {

    const history = useHistory();
    const { search, pathname } = useLocation();

    const loginHandler = useCallback(() => {
        ReactGA.event('log_in_landing');

        history.push('/login')
    }, [history]);

    useEffect(() => {
        const { signUp, about, contact } = referances;

        if (!signUp || !about || !contact) return;
        const query = new URLSearchParams(search);
        const urlTag = query.get("pageType");

        switch (urlTag) {
            case 'howItWorks':
                setTimeout(() => {
                    signUp.current.scrollIntoView();
                }, [200])
                return;
            case 'about':
                setTimeout(() => {
                    about.current.scrollIntoView();
                }, [200])
                return;
            case 'contact':
                setTimeout(() => {
                    contact.current.scrollIntoView();
                }, [200])
                return;
            default:
                return;
        }
    }, [referances, search, history])

    const scrollToHowItWorks = useCallback(() => {
        const { signUp } = referances;
        if (!signUp) return;

        ReactGA.event('how_it_works_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "howItWorks")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            signUp.current.scrollIntoView();
        }, [200]);
    }, [referances, history, search, pathname])


    const scrollToAbout = useCallback(() => {
        const { about } = referances;
        if (!about) return;

        ReactGA.event('about_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "about")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            about.current.scrollIntoView();
        }, [200])
    }, [referances, search, pathname, history])

    const scrollToContact = useCallback(() => {
        const { contact } = referances;
        if (!contact) return;

        ReactGA.event('contact_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "contact")
        history.replace({
            pathname,
            search: query.toString()
        })

        setTimeout(() => {
            contact.current.scrollIntoView();
        }, [200])
    }, [referances, history, search, pathname])

    const onTutorialsClick = useCallback(() => {
        ReactGA.event('tutorials_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "tutorials")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [history, search, pathname])

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "FAQ")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [history, search, pathname])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_header');

        const query = new URLSearchParams(search);
        query.set("pageType", "values")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [history, pathname, search])

    const onLogoClick = () => {
        history.push('/')
    }

    const navigateAllCampaigns = useCallback(() => {

        const query = new URLSearchParams(search);
        query.set("pageType", "campaigns")
        history.replace({
            pathname,
            search: query.toString()
        })
    }, [history, pathname, search])

    return (
        <div className='landingHeader' >
            <div
                style={{
                    cursor: 'pointer'
                }}
                onClick={onLogoClick}
            >
                <Logo />
            </div>
            <div className='navigation'>
                <Title
                    text="How it works"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToHowItWorks}
                />
                <Title
                    text="About"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToAbout}
                />
                <Title
                    text="Contact"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToContact}
                />
                <Title
                    text="All Campaigns"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={navigateAllCampaigns}
                />
                <Title
                    text="Our Values"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onOurValuesClick}
                />
                <Title
                    text="FAQ's"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onFAQClick}
                />
                <Title
                    text="Tutorials"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onTutorialsClick}
                />
            </div>
            <div className="navigation">
                <CustomButton
                    isWhite
                    title='Log in'
                    onClick={loginHandler}
                    style={{ width: '172px' }}
                />
            </div>
            <DonateButton />
            <div className='mobileMenu'>
                <MobileMenuLanding
                    scrollToAbout={scrollToAbout}
                    scrollToContact={scrollToContact}
                    scrollToHowItWorks={scrollToHowItWorks}
                />
            </div>
        </div>
    )
}

export default LandingHeader;