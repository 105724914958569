import { shallowEqual, useSelector } from "react-redux";
import Title from "../../../uiKit/Title/Title";
import { CampaignsList } from "../../Campaigns/campaigns";
import DonateButton from "./donateButton";
import Donate from '../../../uiKit/assets/svg/donate.svg';

const PAGE_INFO_TEXT = "Make your impact where it matters most. Select the campaign you’d like to support, or simply use the ‘Donate to All’ feature to help everyone at once."

const LandingCampaigns = () => {
    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    return <div className="landingCampaignsWrapper">
        <div className="landingCampaignsHeader">
            <div className="landingCampaignsAbout">
                <Title
                    text="All Campaigns"
                    fontWeight={700}
                    lineHeight={screenWidth > 900 ? 65 : 52}
                    fontSize={screenWidth > 900 ? 54 : 40}
                    style={{ marginBottom: "24px" }}
                />
                <Title
                    text={PAGE_INFO_TEXT}
                    fontWeight={300}
                    lineHeight={screenWidth > 900 ? 30 : 22}
                    fontSize={screenWidth > 900 ? 22 : 18}
                    style={{ marginBottom: "32px" }}
                />
                <DonateButton />
            </div>
            <div className="campaignImage">
                <img alt='' src={Donate} style={{ width: '100%', height: '100%' }} />
            </div>
        </div>
        <div className="campaignsWrapper">
            <CampaignsList />
        </div>
    </div>
}

export default LandingCampaigns;