export const FilterIcon = () => (
    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5960_17124)">
            <rect x="0.5" y="0.5" width="47" height="39" rx="9.5" fill="white" />
            <rect x="0.5" y="0.5" width="47" height="39" rx="9.5" stroke="#379AFF" />
            <path
                d="M17 14.7734C17 14.1445 17.4922 13.625 18.1211 13.625H29.8516C30.4805 13.625 31 14.1445 31 14.7734C31 15.0469 30.8906 15.293 30.7266 15.4844L26.1875 21.0898V25C26.1875 25.4922 25.7773 25.875 25.2852 25.875C25.0938 25.875 24.9023 25.8203 24.7383 25.6836L22.2227 23.6875C21.9492 23.4688 21.8125 23.168 21.8125 22.8398V21.0898L17.2461 15.4844C17.082 15.293 17 15.0469 17 14.7734ZM18.4766 14.9375L22.9609 20.4336C23.0703 20.5703 23.125 20.707 23.125 20.8438V22.7305L24.875 24.125V20.8438C24.875 20.707 24.9023 20.5703 25.0117 20.4336L29.4961 14.9375H18.4766Z"
                fill="#2087EF" />
        </g>
        <defs>
            <clipPath id="clip0_5960_17124">
                <rect width="48" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>
)