import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams, useHistory } from 'react-router-dom';

import { blue100, gray010, gray300, yellow400 } from "../../../../uiKit/assets/colors/colors";
import CustomDialog from "../../../../uiKit/CustomDialog/customDialog";
import CustomButton from "../../../../uiKit/Button/CustomButton";
import Title from "../../../../uiKit/Title/Title";
import { MailMan } from "../../../../uiKit/assets/svg/mailMan";

import { getCampaignBalance, getCampaignNetBalance } from "../../../../redux/state/paymentSlice";
import { stopCampaign } from "../../../../redux/state/campaignSlice";

const CampaignBalanceInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { campaignId, communityId } = useParams();

    const [balanceValue, setBalanceValue] = useState(500);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const isAdmin = useSelector(state => state.community.community.role, shallowEqual);
    const campaignBalance = useSelector(state => state.payment.campaignBalance, shallowEqual);
    const campaignNetBalance = useSelector(state => state.payment.campaignNetBalance, shallowEqual);
    const isLoading = useSelector(state => state.payment.isLoading.getCampaignBalance, shallowEqual);
    const isLoadingStopCampaign = useSelector(state => state.campaign.isLoading.stopCampaign, shallowEqual);
    const campaign = useSelector(state => state.campaign.campaign, shallowEqual);
    const target = campaign.amount;
    const pendingBalanceProgress = pendingBalance / target * 100;

    useEffect(() => {
        dispatch(getCampaignBalance({ communityId, campaignId }))
            .then(unwrapResult)
            .then(response => {
                const value = response.campaignBalance.balance;
                const progress = value / target * 100;
                if (progress > 100) {
                    setBalanceValue(100);
                } else {
                    setBalanceValue(progress);
                }
            })
            .catch(({ title }) => {
                console.error(title);
            })
    }, [dispatch, communityId, campaignId, target])

    useEffect(() => {
        if (!campaignId || !communityId) return;
        dispatch(getCampaignNetBalance({ communityId, campaignId }))
    }, [campaignId, communityId, dispatch])

    const stopCampaignHandler = () => {
        if (!!communityId && !!campaignId) {
            dispatch(stopCampaign({ communityId, campaignId }))
                .then(unwrapResult)
                .then(() => history.push('/campaigns'))
                .catch(() => setConfirmOpen(false))
        }
    }

    return (
        <div>
            <div style={{ margin: '12px 0', position: 'relative', width: '100%', height: '16px' }}>
                <div style={{
                    backgroundColor: yellow400,
                    width: `${pendingBalanceProgress}%`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '16px',
                    zIndex: 3,
                    float: 'right',
                    borderRight: `2px solid ${gray010}`,
                    borderRadius: '4px 0 0 4px',
                }} >
                    <Title text={`${pendingBalance}$`} color={gray010} style={{ float: 'right', margin: '1px 8px' }} lineHeight={14} />
                </div>
                {!isLoading && <div style={{
                    width: `${balanceValue}%`,
                    backgroundColor: blue100,
                    position: 'absolute',
                    top: 0,
                    height: '16px',
                    left: 0,
                    zIndex: 2,
                    borderRight: `2px solid ${gray010}`,
                    borderRadius: '4px 0 0 4px',
                }} >
                    <Title text={`${campaignBalance.balance}$`} color={gray010} style={{ float: 'right', margin: '1px 8px' }} lineHeight={14} />
                </div>}
                <div style={{
                    width: '100%',
                    backgroundColor: gray300,
                    position: 'absolute',
                    top: 0,
                    height: '16px',
                    left: 0,
                    zIndex: 1,
                    float: 'right',
                    borderRadius: '4px',
                }} >
                    <Title text={`${target}$`} lineHeight={14} style={{ float: 'right', margin: '1px 8px' }} />
                </div>
            </div>
            <div className="campaignInfoWrapper" >
                <div className="campaignBalanceInfo" >
                    <div style={{
                        display: 'flex'
                    }}>
                        <Title text={`$${campaignBalance.balance.toLocaleString()}`} />
                        <Title
                            text={`collected of $${target.toLocaleString()}`}
                            fontWeight={400}
                            style={{
                                marginLeft: '4px'
                            }}
                        />
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>

                        <Title text={`$${pendingBalance.toLocaleString()}`} />
                        <Title
                            text={` pending payment`}
                            fontWeight={400}
                            style={{
                                marginLeft: '4px'
                            }}
                        />
                    </div>
                </div>
                {
                    !!isAdmin && (
                        <div className="campaignAdminButtons">
                            <CustomButton
                                title='Send partial funds'
                                disabled={!pendingBalance}
                            />
                            <CustomButton
                                title='Close Campign'
                                isWhite
                                onClick={() => setConfirmOpen(true)}
                                disabled={!campaignBalance.balance}
                            />

                            <CustomDialog
                                confirmOpen={confirmOpen}
                                onClose={() => setConfirmOpen(false)}
                                Image={MailMan}
                                mainButtonTitle='Confirm'
                                mainButtonAction={stopCampaignHandler}
                                isLoading={isLoadingStopCampaign}
                                secondButton={true}
                                title={`Please note, this action will stop the ${campaign.name}.`}
                                firstDescription={`You're sending $${campaignBalance.balance.toLocaleString()} to recipient. Please note that the amount the recipient will actually receive will be $${campaignNetBalance.balance.toLocaleString()} (after the credit card processing fees are deducted)`}
                                secondDescription={`Do you confirm this action?`}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default CampaignBalanceInfo;