import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation, useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { debounce } from 'lodash';
import { makeStyles } from "@material-ui/styles";
import { SwipeableDrawer } from "@material-ui/core";

import './campaigns.scss';
import Title from '../../uiKit/Title/Title';
import CustomButton from '../../uiKit/Button/CustomButton';
import CustomSelect from "../../uiKit/CustomSelect/CustomSelect";
import CustomInput from "../../uiKit/Input/CustomInput";
import SearchInput from "../../uiKit/Input/SearchInput";
import { Close, Magnificer } from "../../uiKit/assets/svg/icons";
import { blue010, blue100 } from "../../uiKit/assets/colors/colors";
import { FilterIcon } from "../../uiKit/assets/svg/filterIcon";

import CampaignsDesktopList from "./CampaignsDesktopList/campaignsDesktopList";
import DonateButton from "../Account/LandingPage/donateButton";
import MobileMenu from "../../components/Sidebar/MobileMenu/mobileMenu";

import { getAllActiveCampaigns, getCampaignTypes } from "../../redux/state/campaignSlice";
import { getApprovedCommunities } from "../../redux/state/communitySlice";

const useStyles = makeStyles(() => ({
    paper: {
        width: '80%',
        backgroundColor: blue010,
        padding: '24px'
    }
}))

const Campaigns = () => (
    <div className="campaignsWrapper">
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '24px',
        }}>
            <Title text='Campaigns' lineHeight={40} fontSize={32} fontWeight={600} />
            <DonateButton />
            <MobileMenu />
        </div>
        <CampaignsList />
    </div>
)

export const CampaignsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const drawerClasses = useStyles();

    const activeCampaigns = useSelector(state => state.campaign.activeCampaigns, shallowEqual);
    const communities = useSelector(state => state.community.approvedCommunities, shallowEqual);
    const types = useSelector(state => state.campaign.types, shallowEqual);
    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [communityValue, setCommunityValue] = useState({ id: "", fullName: "" });
    const [campaignTypeId, setCampaignTypeId] = useState(0);
    const [searchCampaign, setSearchCampaign] = useState('');
    const [searchCommunity, setSearchCommunity] = useState('');
    const [pageSize, setPageSize] = useState(8);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/campaigns` })
    }, [])

    useEffect(() => {
        if (!types) {
            dispatch(getCampaignTypes())
        }
    }, [types, dispatch])

    useEffect(() => {
        const page = parseInt(query.get('page') ?? 1);
        const communityId = communityValue.id;
        dispatch(getAllActiveCampaigns({
            page,
            pageSize: 9,
            communityId,
            campaignTypeId,
            searchString: searchCampaign,
        }))
    }, [dispatch, query, searchCampaign, campaignTypeId, communityValue])

    useEffect(() => {
        dispatch(getApprovedCommunities({
            page: 1,
            pageSize,
            searchString: searchCommunity,
        }))
    }, [dispatch, searchCommunity, pageSize])

    const communityHandler = (community) => {
        setCommunityValue({ id: community.id, fullName: community.name });
    }

    const campaignTypeHandler = (event) => {
        const id = event.target.value;
        setCampaignTypeId(id);
    }

    const campaignClickHandler = useCallback((campaignId, communityId) => {

        const userId = window.localStorage.getItem("userId");
        ReactGA.event(`active_campaign_click`);
        if (!!userId) {
            history.push({
                pathname: `/campaigns/campaign/active/${communityId}/${campaignId}`,
            });
            return;
        }

        history.push({
            pathname: `/`,
            search: `?pageType=campaign&communityId=${communityId}&campaignId=${campaignId}`
        });
    }, [
        history
    ])

    const campaignSearchHandler = debounce((e) => setSearchCampaign(e.target.value), [1000]);
    const communitySearchHandler = debounce((value) => setSearchCommunity(value), [1000]);

    useEffect(() => {
        if (!!searchCommunity) return;
        setCommunityValue({ id: "", fullName: "" })
    }, [searchCommunity])

    return (
        <div className="campaignsList">

            {
                screenWidth > 767 ? <div className="campaignFiltersWrapper">
                    <div
                        style={{ flex: '1 1 calc(33.333% - 20px)' }}
                    >
                        <SearchInput
                            searchLabelOption={1}
                            label={"Community"}
                            placeholder={"All Communities"}
                            data={!!communities.items ? communities.items : []}
                            str={searchCommunity}
                            setStr={communitySearchHandler}
                            onChoose={communityHandler}
                            value={communityValue}
                            hasNextPage={communities.hasNextPage}
                            totalNumberOfItems={communities.totalNumberOfItems}
                            setPageSize={setPageSize}
                            numberOfItems={communities.numberOfItems}
                        />
                    </div>

                    <CustomSelect
                        label={"Campaign Type"}
                        style={{ flex: '1 1 calc(33.333% - 20px)' }}
                        data={!!types ? [{ id: 0, name: 'All Types' }, ...types] : []}
                        menuWidth={270}
                        onChange={campaignTypeHandler}
                        value={campaignTypeId}
                        placeholder={"All Types"}
                    />
                    <div style={{ flex: '1 1 calc(33.333% - 20px)' }}>
                        <CustomInput
                            label={"Search"}
                            EndAdornment={Magnificer}
                            placeholder="Search"
                            onChange={campaignSearchHandler}
                            value={query.get('searchString')}
                        />
                    </div>
                </div> : <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <CustomInput
                        label={"Search"}
                        EndAdornment={Magnificer}
                        placeholder="Search"
                        onChange={campaignSearchHandler}
                        value={query.get('searchString')}
                    />
                    <div style={{ marginTop: '22px' }} onClick={() => setIsOpen(true)}>
                        <FilterIcon />
                    </div>
                    <SwipeableDrawer
                        anchor='right'
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        onOpen={() => setIsOpen(true)}
                        classes={drawerClasses}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <Title text="Filters" />
                            <div onClick={() => setIsOpen(false)}>
                                <Close />
                            </div>
                        </div>
                        <SearchInput
                            searchLabelOption={1}
                            label={"Community"}
                            placeholder={"All Communities"}
                            data={!!communities.items ? communities.items : []}
                            str={searchCommunity}
                            setStr={communitySearchHandler}
                            onChoose={communityHandler}
                            value={communityValue}
                            hasNextPage={communities.hasNextPage}
                            totalNumberOfItems={communities.totalNumberOfItems}
                            setPageSize={setPageSize}
                            numberOfItems={communities.numberOfItems}
                        />
                        <CustomSelect
                            label={"Campaign Type"}
                            data={!!types ? [{ id: 0, name: 'All Types' }, ...types] : []}
                            menuWidth={270}
                            onChange={campaignTypeHandler}
                            value={campaignTypeId}
                            placeholder={"All Types"}
                        />
                        <CustomButton title={"Close Filters"} onClick={() => setIsOpen(false)} />
                    </SwipeableDrawer>
                </div>
            }
            <CampaignsDesktopList
                campaignType={'active'}
                data={activeCampaigns}
                color={blue100}
                tableUrl="/"
                campaignClick={campaignClickHandler}
            />
        </div>
    )

}

export default Campaigns;