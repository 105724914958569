import React, { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import './createCampaign.scss';
import CustomButton from '../../../uiKit/Button/CustomButton';
import Title from '../../../uiKit/Title/Title';

import Header from '../../../components/Header/header';
import CampaignMessages from './HelperComponents/campaignMessages';
import CampaignsInfos from './HelperComponents/campaignInfos';

import {
    createCampaign,
    createCampaignDraft,
} from '../../../redux/state/campaignSlice';

const CreateCampaign = () => {

    const isLoading = useSelector(state => state.campaign.isLoading.createCampaign, shallowEqual);
    const isLoadingDraft = useSelector(state => state.campaign.isLoading.createCampaignDraft, shallowEqual);
    const isLoadingMessages = useSelector(state => state.campaign.isLoading.getMessages, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const [messages, setMessages] = useState(null);

    const [campaign, setCampaign] = useState({
        name: '',
        campaignTypeId: 0,
        recipientId: null,
        amount: 0,
        description: '',
        excludedMembers: [],
        campaignUrgencyType: 1,
    });

    const saveHandler = () => {

        const data = { ...campaign, communityId, messages }
        dispatch(createCampaign({ communityId, data }))
            .then(unwrapResult)
            .then((res) => {
                setTimeout(() => {
                    history.push(`/campaigns/campaign/active/${communityId}/${res.campaign.id}`);
                }, [1000])
            })
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Creation of campaign failed: ${title}`)
            })
    }

    const saveDraftHandler = () => {

        const data = { ...campaign, messages }

        dispatch(createCampaignDraft({ communityId, data }))
            .then(unwrapResult)
            .then(() => history.push('/settings/admin/drafts/draft'))
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Draft is not saved: ${title}`)
            })
    }

    const updateMessages = useCallback((newMessages) => setMessages(newMessages), [])

    return (
        <>
            <Header title='Campaigns' onClick={() => history.push('/campaigns')} />
            <div className='campaignTitle'>
                <Title
                    text='Create a campaign'
                    fontSize={32}
                    lineHeight={40}
                />
            </div>
            <div className='titleInputWrapper'>
                <CampaignsInfos
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
                <div className='divider' style={{ marginBottom: '8px' }} />
                <CampaignMessages
                    updateMessages={updateMessages}
                    isLoading={isLoadingMessages}
                />
                <div className='divider' />
                <div className='createCampaignButtons'>
                    <CustomButton
                        title='Launch'
                        style={{ marginBottom: '16px' }}
                        onClick={saveHandler}
                        classNames='mr16to0'
                        disabled={isLoading}
                        loading={isLoading}
                    />
                    <CustomButton
                        isWhite
                        title='Save as Draft'
                        onClick={saveDraftHandler}
                        disabled={isLoadingDraft}
                        loading={isLoadingDraft}
                    />
                </div>
            </div>
        </>
    )

}

export default CreateCampaign;