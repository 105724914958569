import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';

import CustomPagination from "../../../uiKit/CustomTable/pagination";
import ProgressBar from "../../../uiKit/ProgressBar/ProgressBar";
import NoCampaigns from "./noCampaigns";
import Badge from "../Campaign/ActiveCampaign/campaignBadge";
import CustomMobilePagination from "../../../uiKit/CustomTable/mobilePagination";
import { shallowEqual, useSelector } from "react-redux";

const CampaignsDesktopList = ({
    data = [],
    color,
    campaignType,
    showNoData = true,
    campaignClick = null,
    tableUrl = '/campaigns'
}) => {
    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const history = useHistory();

    const onCampaignClick = useCallback((id) => {
        ReactGA.event(`${campaignType}_campaign_click`)
        history.push(`/campaigns/campaign/${campaignType}/${id}`)
    }, [history, campaignType])

    return (
        !!data.items && !!data.items.length ? (
            <>
                <div className='campaignsListWrapper'>
                    {data.items
                        .map((campaign, index) => (
                            <div className="campaignWrapper" >
                                <div style={{ position: 'relative' }}>
                                    <img src={campaign.images.desktop} className="photoWrapperCampaign" alt="" style={{ height: '147px' }} />
                                    <Badge campaignUrgencyType={campaign.campaignUrgencyType} />
                                </div>

                                <ProgressBar
                                    key={index}
                                    campaignId={campaign.id}
                                    communityId={campaign.communityId}
                                    title={campaign.name}
                                    target={campaign.amount}
                                    color={color}
                                    clickable={true}
                                    onClick={!campaignClick ? () => onCampaignClick(campaign.id) : () => campaignClick(campaign.id, campaign.communityId)}
                                    className=""
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    screenWidth > 767 ? <CustomPagination
                        data={data}
                        tableUrl={tableUrl}
                        pageSize={9}
                        style={{
                            borderRadius: '4px',
                            marginTop: '20px',
                        }}
                    /> : <CustomMobilePagination
                        data={data}
                        tableUrl={tableUrl}
                        style={{
                            borderRadius: '4px',
                            marginTop: '20px',
                        }}
                    />
                }
            </>
        ) : (
            <div className='campaignsListWrapper'>
                {showNoData && <NoCampaigns title={campaignType} />}
            </div>
        )
    )
}

export default CampaignsDesktopList;