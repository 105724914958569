import API from '../API/main';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialCampaignState = {
    isLoading: {
        launchCampaignDraft: false,
        launchCampaignSuggestion: false,
        campaignDonations: false,
        drafts: false,
        getCampaignTypes: false,
        getCampaignUrgencyTypes: false,
        createCampaign: false,
        getActiveCampaigns: false,
        getCompletedCampaigns: false,
        getStoppedCampaign: false,
        stopCampaign: false,
        getMessages: false,
        createCampaignDraft: false,
        getCampaignDraft: false,
        getCampaignSuggestion: false,
        updateCampaignDraft: false,
        saveCampaignSuggestion: false,
        suggestions: false,
        getActiveCampaignsPublic: false,
        common: false
    },
    types: null,
    urgencyTypes: null,
    activeCampaigns: {
        items: null,
        numberOfItems: 0,
        totalNumberOfItems: 0,
        page: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    completedCampaigns: {
        items: null,
        numberOfItems: 0,
        totalNumberOfItems: 0,
        page: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    campaign: {
        id: null,
        communityId: null,
        name: null,
        campaignTypeId: 0,
        campaignUrgencyType: {
            id: null,
            name: null,
        },
        amount: 0,
        description: null,
        images: {
            mobile: null,
            desktop: null
        },
        status: null,
        recipient: {
            id: null,
            fullName: null
        }
    },
    completedCampaign: {
        id: null,
        communityId: null,
        name: null,
        campaignTypeId: 0,
        campaignUrgencyType: {
            id: null,
            name: null,
        },
        amount: 0,
        description: null,
        images: {
            mobile: null,
            desktop: null
        },
        status: null,
        recipient: {
            id: null,
            fullName: null
        }
    },
    allCampaigns: null,
    error: null,
    messages: null,
    drafts: {
        items: null,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    campaignDonations: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasNextPage: false,
        hasPreviousPage: false
    },
    suggestedDrafts: {
        items: null,
        numberOfItems: 0,
        page: 0,
        totalPages: 0,
        totalNumberOfItems: 0,
        hasNextPage: false,
        hasPreviousPage: false
    },
    suggestedDraft: null,
    notFound: false
}

export const getCampaignUrgencyTypes = createAsyncThunk(
    '/campaign/urgency/types',
    async (_, thunkAPI) => {
        const response = await API.CampaignAPI.getCampaignUrgencyTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { urgencyTypes: response }
    }
)

export const getCampaignTypes = createAsyncThunk(
    '/campaign/types',
    async (_, thunkAPI) => {
        const response = await API.CampaignAPI.getCampaignTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { types: response }
    }
)

export const createCampaign = createAsyncThunk(
    '/campaign/create',
    async ({ communityId, data }, thunkAPI) => {
        const response = await API.CampaignAPI.createCampaign(communityId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getActiveCampaigns = createAsyncThunk(
    '/campaigns/active',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getActiveCampaigns(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaigns: response }
    }
)

export const getAllActiveCampaigns = createAsyncThunk(
    '/campaigns/all/active',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getAllActiveCampaigns(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaigns: response }
    }
)

export const getActiveCampaignsPublic = createAsyncThunk(
    '/campaigns/active/public',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getActiveCampaignsPublic(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaigns: response }
    }
)

export const getCompletedCampaigns = createAsyncThunk(
    '/campaigns/completed',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getCompletedCampaigns(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaigns: response }
    }
)

export const getCampaign = createAsyncThunk(
    '/campaigns/campaign',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getCampaign(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getActiveCampaign = createAsyncThunk(
    '/campaigns/campaign/active',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getActiveCampaign(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateActiveCampaign = createAsyncThunk(
    '/campaigns/campaign/active/update',
    async ({ communityId, campaignId, data }, thunkAPI) => {
        const response = await API.CampaignAPI.updateActiveCampaign({ communityId, campaignId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getCampaignPublic = createAsyncThunk(
    '/campaigns/campaign/public',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getCampaignPublic(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getStoppedCampaign = createAsyncThunk(
    '/campaigns/campaign/completed',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getStoppedCampaign(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getCompletedCampaign = createAsyncThunk(
    '/campaigns/campaign/finished',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getCompletedCampaign(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getCompletedCampaignPublic = createAsyncThunk(
    '/campaigns/campaign/finished/public',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.getCompletedCampaignPublic(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const stopCampaign = createAsyncThunk(
    '/campaign/stop',
    async (data, thunkAPI) => {
        const response = await API.CampaignAPI.stopCampaign(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: initialCampaignState.campaign }
    }
)

//messages

export const getMessages = createAsyncThunk(
    '/campaign/messages',
    async (id, thunkAPI) => {
        const response = await API.MessagesAPI.getMessages(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { messages: response }
    }
)


//drafts 

export const createCampaignDraft = createAsyncThunk(
    '/campaign/createDraft',
    async ({ communityId, data }, thunkAPI) => {
        const response = await API.DraftAPI.createCampaignDraft(communityId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const launchCampaignDraft = createAsyncThunk(
    '/campaign/launchDraft',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.launchCampaignDraft(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const getCampaignDraft = createAsyncThunk(
    '/campaign/getDraft',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.getCampaignDraft(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const updateCampaignDraft = createAsyncThunk(
    '/campaign/updateDraft',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.updateCampaignDraft(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaign: response }
    }
)

export const deleteCampaignDraft = createAsyncThunk(
    '/campaign/deleteDraft',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.deleteCampaignDraft(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCommunityDrafts = createAsyncThunk(
    '/campaign/drafts',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.getCampaignDrafts(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { campaignDrafts: response }
    }
)

//suggested drafts

export const getCampaignSuggestions = createAsyncThunk(
    '/campaign/suggestions',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.getCampaignSuggestions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCampaignSuggestion = createAsyncThunk(
    '/campaign/suggestion',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.getCampaignSuggestion(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const suggestCampaignDraft = createAsyncThunk(
    '/campaign/suggest/draft',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.suggestDraft(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const saveCampaignSuggestion = createAsyncThunk(
    '/campaign/update/suggestion',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.updateSuggestion(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const launchCampaignSuggestion = createAsyncThunk(
    '/campaign/launch/suggestion',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.launchSuggestion(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteCampaignSuggestion = createAsyncThunk(
    '/campaign/delete/suggestion',
    async (data, thunkAPI) => {
        const response = await API.DraftAPI.deleteCampaignSuggestion(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

//donations

export const getCampaignDonations = createAsyncThunk(
    '/campaign/donations',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getCampaignDonations(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { donations: response }
    }
)

export const getCampaignDonationsPublic = createAsyncThunk(
    '/campaign/donations',
    async (data, thunkAPI) => {
        const response = await API.PaymentAPI.getCampaignDonationsPublic(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return { donations: response }
    }
)

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: initialCampaignState,
    reducers: {
        clearCampaigns(state) {
            state.activeCampaigns = initialCampaignState.activeCampaigns;
            state.completedCampaigns = initialCampaignState.completedCampaigns;
            state.allCampaigns = initialCampaignState.allCampaigns;
        },
        clearDrafts(state) {
            state.drafts = initialCampaignState.drafts;
        },
        clearSuggestions(state) {
            state.suggestedDrafts = initialCampaignState.suggestedDrafts;
        },
        clearDonations(state) {
            state.campaignDonations = initialCampaignState.campaignDonations;
        },
        setNotFound(state, action) {
            state.notFound = action.payload;
        }
    },
    extraReducers: {
        [getCampaignUrgencyTypes.pending]: (state) => {
            state.isLoading.getCampaignUrgencyTypes = true;
        },
        [getCampaignUrgencyTypes.fulfilled]: (state, action) => {
            state.isLoading.getCampaignUrgencyTypes = false;
            state.urgencyTypes = action.payload.urgencyTypes;
            state.error = null;
        },
        [getCampaignUrgencyTypes.rejected]: (state, action) => {
            state.isLoading.getCampaignUrgencyTypes = false;
            state.error = action.error || action.payload;
        },
        [launchCampaignDraft.pending]: (state) => {
            state.isLoading.launchCampaignDraft = true;
        },
        [launchCampaignDraft.fulfilled]: (state) => {
            state.isLoading.launchCampaignDraft = false;
            state.error = null;
        },
        [launchCampaignDraft.rejected]: (state, action) => {
            state.isLoading.launchCampaignDraft = false;
            state.error = action.error || action.payload;
        },
        [getCampaignDonations.pending]: (state) => {
            state.isLoading.campaignDonations = true;
        },
        [getCampaignDonations.fulfilled]: (state, action) => {
            state.isLoading.campaignDonations = false;
            state.campaignDonations = action.payload.donations;
            state.error = null;
        },
        [getCampaignDonations.rejected]: (state, action) => {
            state.isLoading.campaignDonations = false;
            state.error = action.error || action.payload;
        },
        [getCampaignDonationsPublic.pending]: (state) => {
            state.isLoading.campaignDonations = true;
        },
        [getCampaignDonationsPublic.fulfilled]: (state, action) => {
            state.isLoading.campaignDonations = false;
            state.campaignDonations = action.payload.donations;
            state.error = null;
        },
        [getCampaignDonationsPublic.rejected]: (state, action) => {
            state.isLoading.campaignDonations = false;
            state.error = action.error || action.payload;
        },
        [getCommunityDrafts.pending]: (state) => {
            state.isLoading.drafts = true;
        },
        [getCommunityDrafts.fulfilled]: (state, action) => {
            state.isLoading.drafts = false;
            state.drafts = action.payload.campaignDrafts;
            state.error = null;
        },
        [getCommunityDrafts.rejected]: (state, action) => {
            state.isLoading.drafts = false;
            state.error = action.error || action.payload;
        },
        [getCampaignTypes.pending]: (state) => {
            state.isLoading.getCampaignTypes = true;
        },
        [getCampaignTypes.fulfilled]: (state, action) => {
            state.isLoading.getCampaignTypes = false;
            state.types = action.payload.types;
            state.error = null;
        },
        [getCampaignTypes.rejected]: (state, action) => {
            state.isLoading.getCampaignTypes = false;
            state.error = action.error || action.payload;
        },
        [createCampaign.pending]: (state) => {
            state.isLoading.createCampaign = true;
        },
        [createCampaign.fulfilled]: (state, action) => {
            state.isLoading.createCampaign = false;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [createCampaign.rejected]: (state, action) => {
            state.isLoading.createCampaign = false;
            state.error = action.error || action.payload;
        },
        [getActiveCampaigns.pending]: (state) => {
            state.isLoading.getActiveCampaigns = true;
        },
        [getActiveCampaigns.fulfilled]: (state, action) => {
            state.isLoading.getActiveCampaigns = false;
            state.activeCampaigns = action.payload.campaigns;
            state.error = null;
        },
        [getActiveCampaigns.rejected]: (state, action) => {
            state.isLoading.getActiveCampaigns = false;
            state.error = action.error || action.payload;
        },
        [getAllActiveCampaigns.pending]: (state) => {
            state.isLoading.getActiveCampaigns = true;
        },
        [getAllActiveCampaigns.fulfilled]: (state, action) => {
            state.isLoading.getActiveCampaigns = false;
            state.activeCampaigns = action.payload.campaigns;
            state.error = null;
        },
        [getAllActiveCampaigns.rejected]: (state, action) => {
            state.isLoading.getActiveCampaigns = false;
            state.error = action.error || action.payload;
        },
        [getActiveCampaignsPublic.pending]: (state) => {
            state.isLoading.getActiveCampaignsPublic = true;
        },
        [getActiveCampaignsPublic.fulfilled]: (state, action) => {
            state.isLoading.getActiveCampaignsPublic = false;
            state.activeCampaigns = action.payload.campaigns;
            state.error = null;
        },
        [getActiveCampaignsPublic.rejected]: (state, action) => {
            state.isLoading.getActiveCampaignsPublic = false;
            state.error = action.error || action.payload;
        },
        [getCompletedCampaigns.pending]: (state) => {
            state.isLoading.getCompletedCampaigns = true;
        },
        [getCompletedCampaigns.fulfilled]: (state, action) => {
            state.isLoading.getCompletedCampaigns = false;
            state.completedCampaigns = action.payload.campaigns;
            state.error = null;
        },
        [getCompletedCampaigns.rejected]: (state, action) => {
            state.isLoading.getCompletedCampaigns = false;
            state.error = action.error || action.payload;
        },
        [getCampaign.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCampaign.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [getCampaign.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getCampaignPublic.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCampaignPublic.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [getCampaignPublic.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getStoppedCampaign.pending]: (state) => {
            state.isLoading.getStoppedCampaign = true;
        },
        [getStoppedCampaign.fulfilled]: (state, action) => {
            state.isLoading.getStoppedCampaign = false;
            state.completedCampaign = action.payload.campaign;
            state.error = null;
        },
        [getStoppedCampaign.rejected]: (state, action) => {
            state.isLoading.getStoppedCampaign = false;
            state.error = action.error || action.payload;
        },
        [getCompletedCampaign.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCompletedCampaign.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.completedCampaign = action.payload.campaign;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [getCompletedCampaign.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [getCompletedCampaignPublic.pending]: (state) => {
            state.isLoading.common = true;
        },
        [getCompletedCampaignPublic.fulfilled]: (state, action) => {
            state.isLoading.common = false;
            state.completedCampaign = action.payload.campaign;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [getCompletedCampaignPublic.rejected]: (state, action) => {
            state.isLoading.common = false;
            state.error = action.error || action.payload;
        },
        [stopCampaign.pending]: (state) => {
            state.isLoading.stopCampaign = true;
        },
        [stopCampaign.fulfilled]: (state, action) => {
            state.isLoading.stopCampaign = false;
            state.campaign = action.payload.campaign;
            state.error = null;
        },
        [stopCampaign.rejected]: (state, action) => {
            state.isLoading.stopCampaign = false;
            state.error = action.error || action.payload;
        },
        [getMessages.pending]: (state) => {
            state.isLoading.getMessages = true;
        },
        [getMessages.fulfilled]: (state, action) => {
            state.isLoading.getMessages = false;
            state.messages = action.payload.messages;
            state.error = null;
        },
        [getMessages.rejected]: (state, action) => {
            state.isLoading.getMessages = false;
            state.error = action.error || action.payload;
        },
        [createCampaignDraft.pending]: (state) => {
            state.isLoading.createCampaignDraft = true;
        },
        [createCampaignDraft.fulfilled]: (state, action) => {
            state.isLoading.createCampaignDraft = false;
            state.error = null;
        },
        [createCampaignDraft.rejected]: (state, action) => {
            state.isLoading.createCampaignDraft = false;
            state.error = action.error || action.payload;
        },
        [getCampaignDraft.pending]: (state) => {
            state.isLoading.getCampaignDraft = true;
        },
        [getCampaignDraft.fulfilled]: (state, action) => {
            state.isLoading.getCampaignDraft = false;
            state.error = null;
        },
        [getCampaignDraft.rejected]: (state, action) => {
            state.isLoading.getCampaignDraft = false;
            state.error = action.error || action.payload;
        },
        [updateCampaignDraft.pending]: (state) => {
            state.isLoading.updateCampaignDraft = true;
        },
        [updateCampaignDraft.fulfilled]: (state, action) => {
            state.isLoading.updateCampaignDraft = false;
            state.error = null;
        },
        [updateCampaignDraft.rejected]: (state, action) => {
            state.isLoading.updateCampaignDraft = false;
            state.error = action.error || action.payload;
        },
        [deleteCampaignDraft.pending]: (state) => {
            state.isLoading.drafts = true;
        },
        [deleteCampaignDraft.fulfilled]: (state, action) => {
            state.isLoading.drafts = false;
            state.error = null;
        },
        [deleteCampaignDraft.rejected]: (state, action) => {
            state.isLoading.drafts = false;
            state.error = action.error || action.payload;
        },
        [deleteCampaignSuggestion.pending]: (state) => {
            state.isLoading.suggestions = true;
        },
        [deleteCampaignSuggestion.fulfilled]: (state) => {
            state.isLoading.suggestions = false;
            state.error = null;
        },
        [deleteCampaignSuggestion.rejected]: (state, action) => {
            state.isLoading.suggestions = false;
            state.error = action.error || action.payload;
        },
        [getCampaignSuggestion.pending]: (state) => {
            state.isLoading.getCampaignSuggestion = true;
        },
        [getCampaignSuggestion.fulfilled]: (state, action) => {
            state.isLoading.getCampaignSuggestion = false;
            state.suggestedDraft = action.payload;
            state.error = null;
        },
        [getCampaignSuggestion.rejected]: (state, action) => {
            state.isLoading.getCampaignSuggestion = false;
            state.error = action.error || action.payload;
        },
        [getCampaignSuggestions.pending]: (state) => {
            state.isLoading.suggestions = true;
        },
        [getCampaignSuggestions.fulfilled]: (state, action) => {
            state.isLoading.suggestions = false;
            state.suggestedDrafts = action.payload;
            state.error = null;
        },
        [getCampaignSuggestions.rejected]: (state, action) => {
            state.isLoading.suggestions = false;
            state.error = action.error || action.payload;
        },
        [saveCampaignSuggestion.pending]: (state) => {
            state.isLoading.saveCampaignSuggestion = true;
        },
        [saveCampaignSuggestion.fulfilled]: (state, action) => {
            state.isLoading.saveCampaignSuggestion = false;
            state.error = null;
        },
        [saveCampaignSuggestion.rejected]: (state, action) => {
            state.isLoading.saveCampaignSuggestion = false;
            state.error = action.error || action.payload;
        },
        [launchCampaignSuggestion.pending]: (state) => {
            state.isLoading.launchCampaignSuggestion = true;
        },
        [launchCampaignSuggestion.fulfilled]: (state, action) => {
            state.isLoading.launchCampaignSuggestion = false;
            state.error = null;
        },
        [launchCampaignSuggestion.rejected]: (state, action) => {
            state.isLoading.launchCampaignSuggestion = false;
            state.error = action.error || action.payload;
        },
        [suggestCampaignDraft.pending]: (state) => {
            state.isLoading.suggestions = true;
        },
        [suggestCampaignDraft.fulfilled]: (state, action) => {
            state.isLoading.suggestions = false;
            state.error = null;
        },
        [suggestCampaignDraft.rejected]: (state, action) => {
            state.isLoading.suggestions = false;
            state.error = action.error || action.payload;
        },
    }
})

export const {
    clearCampaigns,
    clearDrafts,
    clearSuggestions,
    setNotFound,
    clearDonations,
} = campaignSlice.actions;

export default campaignSlice.reducer;